import {
    AiOutlineDashboard,
    AiOutlineTeam,
    AiOutlineContacts,
    AiOutlineFlag,
    AiOutlineFund,
    AiOutlineSchedule,
    AiOutlineHighlight,
    AiOutlineSketch,
    AiOutlineFire,
    AiOutlineShareAlt,
    AiOutlineVerticalRight,
    AiOutlineLaptop,
    AiOutlineQuestionCircle,
    AiOutlineSetting,
    AiOutlineRobot,
    AiOutlineAlert,
    AiOutlineFolder,
    AiOutlineFileImage
} from 'react-icons/ai'

export const IconHandler = (param: string,
    subParam: string) => {
    switch (param) {
        case 'manage':
            if (subParam === 'dashboard')
                return <AiOutlineDashboard />
            else if (subParam === 'account')
                return <AiOutlineTeam />
            else if (subParam === 'question')
                return <AiOutlineQuestionCircle />
            else if (subParam === 'notice')
                return <AiOutlineAlert />
            else if (subParam === 'storage')
                return <AiOutlineFolder />
            else if (subParam === 'report')
                return <AiOutlineAlert />
            else
                return;
        case "intro":
            if (subParam === "intro")
                return <AiOutlineRobot />
            else if (subParam === 'contact')
                return <AiOutlineContacts />
            else if (subParam === 'business')
                return <AiOutlineFlag />
            else
                return;
        case "news":
            if (subParam === 'news')
                return <AiOutlineFund />
            else if (subParam === 'schedule')
                return <AiOutlineSchedule />
            else
                return;
        case "program":
            if (subParam === 'basic')
                return <AiOutlineHighlight />
            else if (subParam === 'advance')
                return <AiOutlineFire />
            else if (subParam === 'special')
                return <AiOutlineSketch />
            else if (subParam === 'pmanage')
                return <AiOutlineSetting />
            else if (subParam === 'image')
                return <AiOutlineFileImage />
            else
                return;
        case "startup":
            if (subParam === 'register')
                return <AiOutlineShareAlt />
            else if (subParam === 'smanage')
                return <AiOutlineSetting />
            else if (subParam === 'simage')
                return <AiOutlineFileImage />
            else
                return;
        case "aid":
            if (subParam === 'k-training')
                return <AiOutlineVerticalRight />
            else if (subParam === 'kmanage')
                return <AiOutlineSetting />
            else if (subParam === 'aimage')
                return <AiOutlineFileImage />
            else
                return;
        case "reservation":
            if (subParam === 'lab')
                return <AiOutlineLaptop />
            else if (subParam === 'booking')
                return <AiOutlineSetting />

            else
                return;
        default:
            return;
    }
};
