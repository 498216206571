import { useCallback, useRef, useState } from "react"
import Header from "../../Components/Header"
import { MainWrapper } from "../SharedStyle/Style"
import Banner from "./Component/Banner"
import HomeEvent from "./Component/HomeEvent"
import HomeKtraining from "./Component/HomeKtraining"
import HomeNavBar from "./Component/HomeNavBar"
import HomeNotice from "./Component/HomeNotice"
import HomePrograms from "./Component/HomePrograms"
import HomeBottom from "./Component/HomeBottom"
import HomeStartUp from "./Component/HomeStartUp"
import {
	HomeContainer,
	LeftContainer,
	RightContainer,
	NavBar,
	FirstBoard,
	SecondBoard,
	ThirdBoard,
	FifthBoard,
	MobileContent,
} from "./HomeStyles"
import MainFooter from "../../Components/Footer/Footer"
import { useEffect } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ALL_BOOKINGS, RESPONSE_BOOKING } from "../Admin/AdminData/AdminQueries"
import Loading from "../../Components/Loading"
import { GetAllBooking, GetAllBooking_GetAllBooking_bookings } from "../../types/api"
import { convertDay } from "../../Hooks/getDate"
import MobileBanner from "./Component/MobileBanner"

const Home = () => {
	const ref = useRef<HTMLDivElement>(null)
	const [booking, setBooking] = useState<(GetAllBooking_GetAllBooking_bookings | null)[]>()
	const date = new Date()
	const now =
		date.getFullYear() +
		"/" +
		(date.getMonth() + 1) +
		"/" +
		date.getDate() +
		"/(" +
		convertDay(date.getDay().toString()) +
		")"

	const [isSticky, setisSticky] = useState<boolean>(false)

	const handleScroll = useCallback(() => {
		if (window.pageYOffset >= 500) {
			setisSticky(true)
		}
		if (window.pageYOffset < 500) {
			setisSticky(false)
		}
	}, [])

	const { loading } = useQuery<GetAllBooking>(GET_ALL_BOOKINGS, {
		onCompleted: ({ GetAllBooking }) => {
			const { ok, err, bookings } = GetAllBooking
			if (ok && bookings) {
				setBooking(
					bookings &&
					bookings.filter((item) => {
						if (item?.status === "REQUESTING" || item?.status === "ACCEPTED") {
							if (item.from.split(" ")[0] === now) {
								return item
							} else {
								return undefined
							}
						} else return undefined
					})
				)
			} else {
				console.log(err)
			}
		},
	})

	const [responseBooking] = useMutation(RESPONSE_BOOKING, {
		onCompleted: ({ ResponseBooking }) => {
			const { ok, err } = ResponseBooking
			if (ok) {
				console.log("done")
			} else {
				console.log(err)
			}
		},
	})

	useEffect(() => {
		if (booking) {
			for (let i = 0; i < booking.length; i++) {
				if (booking[i]) {
					if (booking[i]!.to.split(" ")[1].split(":")[0] > date.getHours().toString()) {
						responseBooking({
							variables: {
								bookingId: booking[i]!.id,
								userId: booking[i]!.userId,
								action: "FINISHED",
							},
						})
					}
				}
			}
		}
	}, [booking, responseBooking])

	useEffect(() => {
		window.addEventListener("mousewheel", handleScroll)
		return () => {
			window.removeEventListener("mousewheel", handleScroll)
		}
	}, [handleScroll])

	if (loading) {
		return <Loading />
	}

	return (
		<MainWrapper>
			<Header pageRef={ref} home={true} param="home" />
			<Banner />
			<MobileBanner />
			<HomeContainer>
				<LeftContainer show={isSticky}>
					<NavBar>
						<HomeNavBar />
					</NavBar>
				</LeftContainer>
				<RightContainer>
					<FirstBoard>
						<HomeNotice />
						<HomeEvent />
					</FirstBoard>
					<SecondBoard>
						<HomePrograms />
					</SecondBoard>
					<SecondBoard>
						<HomeKtraining />
					</SecondBoard>
					<ThirdBoard>
						<HomeStartUp />
					</ThirdBoard>
					<FifthBoard>
						<HomeBottom />
					</FifthBoard>
				</RightContainer>
				<MobileContent>
					<HomeBottom />
					<HomeNotice />
					<HomeEvent />
					<HomePrograms />
					<HomeKtraining />
					<HomeStartUp />
				</MobileContent>
			</HomeContainer>
			<MainFooter hasImage={true} />
		</MainWrapper>
	)
}

export default Home
