import { useQuery } from "@apollo/client"
import { useState } from "react"
import styled from "styled-components"
import { GetAllBoard } from "../../../types/api"
import { GET_ALL_BOARD } from "../../SharedQueries"
import { Title, SubTitle, NoticeMoreInfo, SubTitles } from "../HomeStyles"
import BoardTable from "./BoardTable"
import { forceHistory } from "../../../Hooks/forceHistory"

const Wrapper = styled.div`
	@media only screen and (min-width: 320px) and (max-width: 768px) {
		width: 100%;
		height: 320px;
		background-color: #e5e5e5;		
		padding:45px;
	}
	@media only screen and (min-width: 769px) {
		width: 40%;
		min-width: 420px;
		margin-top: 3%;
		margin-left: 2%;
	}
`

const Container = styled.div`
	@media only screen and (min-width: 320px) and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		width:330px;
		margin:0 auto;
	}
	@media only screen and (min-width: 769px) {
		width:100%;
		margin-left: 25px;
	}
`

const HomeNotice = () => {
	const [category, setCategory] = useState<string>("notice")
	const [notice, setNotice] = useState<any[]>()
	const [storage, setStorage] = useState<any[]>()

	const getCategory = (category: string) => {
		setCategory(category)
	}

	const { loading } = useQuery<GetAllBoard>(GET_ALL_BOARD, {
		onCompleted: ({ GetAllBoard }) => {
			const { ok, err, boards } = GetAllBoard
			if (ok && boards) {
				setNotice(boards && boards.filter((item) => item?.category === "notice"))
				setStorage(boards && boards.filter((item) => item?.category === "storage"))
			} else if (!ok && err) {
				console.log(err)
			}
		},
	})

	const handleBoard = () => {
		switch (category) {
			case "notice":
				return <BoardTable category={category} data={notice} />
			case "storage":
				return <BoardTable category={category} data={storage} />
			default:
				return <>error</>
		}
	}

	if (loading) {
		return <>Loading</>
	}
	return (
		<Wrapper>
			<Container >
				<Title>커뮤니티</Title>
				<SubTitles>
					<SubTitle
						onClick={() => getCategory("notice")}
						active={category === "notice" ? 1 : 0}
					>
						공지사항
				</SubTitle>
					<SubTitle
						onClick={() => getCategory("storage")}
						active={category === "storage" ? 1 : 0}
					>
						자료실
				</SubTitle>
				</SubTitles>
				<div>{handleBoard()}</div>
				<NoticeMoreInfo onClick={() => forceHistory.push(`/board/${category}`)}>
					더보기 +
			</NoticeMoreInfo>
			</Container>
		</Wrapper>
	)
}
export default HomeNotice
