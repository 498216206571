import { useParams } from 'react-router'
import { forceHistory } from '../../../Hooks/forceHistory'
import { NavigationData } from '../AdminData/NavigationData'
import { NavContent, Navigation, NavRow, NavSub, NavSubTitle, NavTitle } from '../Style'
import { IconHandler } from './IconHandler'

interface ParamProps {
    param: string,
    subparam: string
}

const AdminNavigation = () => {
    const { param, subparam } = useParams<ParamProps>()
    return (
        <Navigation>
            {NavigationData.map((d, i) => {
                return (
                    <NavRow key={i}>
                        <NavTitle action={param === d.param ? 1 : 0}>
                            {d.title}
                        </NavTitle>
                        <NavContent>
                            {d.category.map((elem, idx) => {
                                return (
                                    <NavSub key={idx}>
                                        <div onClick={() => forceHistory.push(`/admin/${elem.param}/${elem.subParam}`)}>
                                            <NavSubTitle action={subparam === elem.subParam ? 1 : 0}>
                                                {IconHandler(elem.param, elem.subParam)}&ensp;&ensp;{elem.title}
                                            </NavSubTitle>
                                        </div>
                                    </NavSub>
                                )
                            })}
                        </NavContent>
                    </NavRow>
                )
            })}
        </Navigation>
    )
}

export default AdminNavigation