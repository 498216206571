import styled from "styled-components";
import { Link } from "react-router-dom";

interface IStyle {
  url: string;
}

export const LoginStyle = {
  Container: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 100%;
      background-color: white;
      padding: 70px 0;
    }
    @media screen and (min-width: 769px) {
      height: 100%;
      width: 100%;
      display: flex;
    }
  `,
  Form: styled.div`
    @media only screen and (min-width: 320px) and(max-width:767px) {
      //input
    }
    @media screen and (min-width: 769px) {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      input {
        width: 260px;
        height: 35px;
        margin: 1px 15px 5px 3px;
      }
    }
  `,
  FormContainer: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    @media screen and (min-width: 769px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin-left: 50px;
    }
  `,
  FormLeftContainer: styled.div``,
  FormRightContainer: styled.div``,
  Image: styled.div<IStyle>`
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 320px;
    }
    @media only screen and (min-width: 769px) {
      height: 100%;
      width: 100%;
      background-image: url(${(props) => props.url});
      background-size: cover;
      background-position: center;
    }
  `,

  Box: styled.div`
    @media only screen and (min-width: 320px) and(max-width:767px) {
    }
    @media screen and (min-width: 769px) {
      border: 2px solid;
      width: 35%;
      height: 75%;
      margin-top: 5%;
      margin-bottom: 8%;
      background-color: #ffffff;
    }
  `,

  TopWrapper: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 769px) {
      width: 35%;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 6% 0 -3% 0;
      justify-content: center;
      margin-right: 6%;
    }
  `,

  TopLogo: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
    }
    @media screen and (min-width: 769px) {
      img {
        width: 80%;
        height: 40%;
        margin-top: 10px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      justify-content: space-between;
      cursor: pointer;
    }
  `,

  TopTitle: styled.div`
    @media only screen and (min-width: 320px) and(max-width:767px) {
    }
    @media screen and (min-width: 769px) {
      font-size: 16px;
      letter-spacing: 0.22px;
      text-align: left;
      margin-left: -15%;
      margin-right: 5%;
      margin-bottom: -3%;
      color: #ffffff;
    }
  `,

  MiddleWrapper: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 70px;
    }
    @media screen and (min-width: 769px) {
      margin: 0px 181px 15px 55px;
    }
  `,
  MiddleTitle: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      font-size: 30px;
      color: ${(props) => props.theme.palette.primary};
      margin-bottom: 15px;
    }
    @media screen and (min-width: 769px) {
      width: 115px;
      height: 23px;
      margin-top: 10px;
      margin-bottom: 20px;
      margin-left: -2px;
      font-size: 20px;
      line-height: 2.51;
      letter-spacing: 0.26px;
      color: #04083e;
    }
  `,
  Bottom: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 769px) {
      margin: 0px 181px 15px 55px;
    }
  `,
  MobileButtom: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: flex;
    }
    @media screen and (min-width: 769px) {
      display: none;
    }
  `,
  MiddleDesc: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      font-size: 18px;
    }
    @media screen and (min-width: 769px) {
      width: 212px;
      height: 12px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      white-space: nowrap;
      letter-spacing: 0.13px;
      text-align: left;
      color: #444444;
    }
  `,

  MiddleBar: styled.div`
    @media only screen and (min-width: 320px) and(max-width:767px) {
    }
    @media screen and (min-width: 769px) {
      width: 85%;
      height: 2px;
      margin-top: 1%;
      margin-bottom: 5%;
      border: solid 1px #6c6c6c;
      background-color: #6c6c6c;
    }
  `,
  LoginButton: styled.button`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 300px;
      height: 45px;
      background-color: #04083e;
      border: none;
      border-radius: 4px;
      color: white;
      font-size: 16px;
      box-shadow: 2px 2px 4px 1px #d6d6d6;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 769px) {
      width: 78px;
      height: 78px;
      margin: 10px 35px 11px 0px;
      background-color: #ec008c;
      color: ${(props) => (props.value ? "white" : "white")};
      cursor: pointer;
      text-align: center;
      border: none;
      opacity: ${(props) => (props.disabled ? "0.5" : "1")};
      cursor: pointer;
      &:hover {
        opacity: ${(props) => (props.disabled ? "0.5" : "1")};
        transition: 0.2s linear;
      }
    }
  `,
  SignUpButton: styled.div`
    @media only screen and (min-width: 320px) and(max-width:767px) {
    }
    @media screen and (min-width: 769px) {
      width: 340px;
      height: 55px;
      margin-bottom: 10%;
      background-color: #04083e;
      color: #ffffff;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-align: center;
      border: none;
      &:hover {
        background-color: ${(props) => props.theme.palette.secondary};
        transition: 0.2s linear;
      }
    }
  `,
  Id: styled.div``,

  PW: styled.div``,
  SearchWrapper: styled.div`
    @media only screen and (min-width: 320px) and(max-width:767px) {
    }
    @media screen and (min-width: 769px) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-right: 15%;
      width: 100%;
      font-size: 15.5px;
      letter-spacing: 0.16px;
      text-align: left;
      color: #444444;
      margin-bottom: 5%;
    }
  `,

  BacktoHome: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 250px;
      color: #444444;
    }
    @media screen and (min-width: 769px) {
      font-size: 13px;
      color: #878787;
      letter-spacing: 0.13px;
      margin-left: -82%;
      &:hover {
        color: lightgray;
        transition: 0.2s linear;
      }
    }
  `,
  PCLink: styled(Link)`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 769px) {
    }
  `,
  MobileLink: styled(Link)`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      font-size: 16px;
      color: ${(props) => props.theme.palette.primary};
    }
    @media screen and (min-width: 769px) {
      display: none;
    }
  `,
};
