import { useReactiveVar } from "@apollo/client"
import React, { useEffect, useState, useCallback } from "react"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import { isLoggedInVar } from "../../Apollo/apollo"
import { userLogOut } from "../../Apollo/loginResolvers"
import { HeaderMenuData, HeaderSubMenu } from "./HeaderData"
import {
	Container,
	ExtendedLink,
	HeaderCol,
	MainHeader,
	TopHeader,
	TopHeaderCol,
	Wrapper,
	Burger,
	MobileMenu,
	PCMenu,
	MenuCategory,
	MenuSubCategory,
	MenuSubCategoryCol,
	CategoryRow,
	MenuCategoryCol,
	CategorySecondRow,
	LogOutButton,
	HeaderImage,
} from "./style"
import MobileHeader from "./MobileHeader"
interface IProps {
	pageRef?: React.RefObject<HTMLDivElement>
	home: boolean
	param: string
}



const Header: React.FC<IProps> = ({ pageRef, home, param }) => {
	const history = useHistory()
	const isLoggedIn = useReactiveVar(isLoggedInVar)
	const [isSticky, setisSticky] = useState<boolean>(false)

	const handleScroll = useCallback(() => {
		if (window.pageYOffset >= 500) {
			setisSticky(true)
		}
		if (window.pageYOffset < 500) {
			setisSticky(false)
		}
	}, [])

	useEffect(() => {
		window.addEventListener("mousewheel", handleScroll)
		return () => {
			window.removeEventListener("mousewheel", handleScroll)
		}
	}, [handleScroll])

	const logOutHandler = () => {
		userLogOut()
		history.push("/")
	}

	return (
		<Wrapper show={isSticky}>
			<TopHeader stylehome={home ? true : false}>
				<TopHeaderCol>SUN MOON UNIVERSITY</TopHeaderCol>
				<TopHeaderCol>
					{isLoggedIn ? (
						<ExtendedLink to={`/profile`}>마이페이지</ExtendedLink>
					) : (
						<ExtendedLink to={"/signup"}>회원가입</ExtendedLink>
					)}
					|
					{isLoggedIn ? (
						<LogOutButton onClick={() => logOutHandler()}>로그아웃</LogOutButton>
					) : (
						<ExtendedLink to={"/login"}>로그인</ExtendedLink>
					)}
				</TopHeaderCol>
			</TopHeader>
			<MainHeader show={isSticky} home={home}>
				<Container>
					<HeaderCol>
						<Link to="/" style={{ maxWidth: 200 }}>
							<HeaderImage src={`/images/logo.png`} alt="sunmon-logo" />
						</Link>
					</HeaderCol>
					<Burger></Burger>
					<Burger></Burger>
					<HeaderCol>
						<PCMenu>
							<CategoryRow>
								{HeaderMenuData.map((data, i) => {
									return (
										<MenuCategoryCol key={i}>
											<MenuCategory>
												<ExtendedLink
													to={`/${data.param}/${data.category[0].subParam}`}
												>
													{data.title}
												</ExtendedLink>
											</MenuCategory>
										</MenuCategoryCol>
									)
								})}
								<CategorySecondRow>
									{HeaderSubMenu.map(({ data }, i) => {
										return (
											<MenuSubCategoryCol key={i}>
												{data.map((elem, idx) => {
													return (
														<MenuSubCategory key={idx}>
															<ExtendedLink
																to={`/${elem.param}/${elem.subParam}`}
															>
																{elem.title}
															</ExtendedLink>
														</MenuSubCategory>
													)
												})}
											</MenuSubCategoryCol>
										)
									})}
								</CategorySecondRow>
							</CategoryRow>
						</PCMenu>
						<MobileMenu>
							<Burger>|||</Burger>
						</MobileMenu>
					</HeaderCol>
				</Container>
			</MainHeader>
			<MobileHeader />
		</Wrapper>
	)

}

export default Header
