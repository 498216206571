import { gql } from "@apollo/client";

export const CREATE_ACCOUNT_MUTATION = gql`
  mutation CreateAccount(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $address: String!
    $year: String!
    $month: String!
    $date: String!
  ) {
    CreateAccount(
      date: $date
      month: $month
      year: $year
      address: $address
      phone: $phone
      lastName: $lastName
      firstName: $firstName
      password: $password
      email: $email
    ) {
      ok
      err
    }
  }
`;
export const Get_All_QUESTION = gql`
  query SeeAllQuestions {
    SeeAllQuestions {
      ok
      err
      question {
        id
        title
        content
        user {
          id
          firstName
          lastName
        }
        replies {
          id
        }
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const GET_QUESTIONS_BY_CATEGORY = gql`
  query GetQuestionsByCategory($category: String!) {
    GetQuestionsByCategory(category: $category) {
      ok
      err
      questions {
        id
        title
        content
        user {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEE_QUESTION = gql`
  query SeeQuestion($id: Int!, $route: String) {
    SeeQuestion(id: $id, route: $route) {
      ok
      err
      question {
        id
        title
        content
        user {
          id
          firstName
          lastName
        }
        replies {
          id
          content
          questionId
          admin
          user {
            id
            firstName
          }
          userId
          questionId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const EDIT_USER = gql`
  mutation EditUser(
    $id: Int!
    $route: String
    $phone: String
    $address: String
  ) {
    EditUser(id: $id, route: $route, phone: $phone, address: $address) {
      ok
      err
    }
  }
`;

export const GET_BOARD_BY_CATEGORY = gql`
  query GetBoardByCategory($category: String!) {
    GetBoardByCategory(category: $category) {
      ok
      err
      board {
        id
        title
        content
        imageUrl
        hasImages
        hasFiles
        category
        files {
          url
          fileName
        }
        imageName
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_BOARD_BY_ID = gql`
  query GetBoardById($id: Int!) {
    GetBoardById(id: $id) {
      ok
      err
      board {
        id
        title
        content
        imageUrl
        hasImages
        hasFiles
        category
        files {
          id
          url
          fileName
        }
        imageName
        createdAt
      }
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation CreateQuestion(
    $title: String!
    $content: String!
    $route: String
    $category: String!
  ) {
    CreateQuestion(
      title: $title
      content: $content
      route: $route
      category: $category
    ) {
      ok
      err
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($id: Int!, $route: String) {
    DeleteQuestion(id: $id, route: $route) {
      ok
      err
    }
  }
`;

export const EDIT_QUESTION = gql`
  mutation EditQuestion($id: Int!, $title: String, $content: String) {
    EditQuestion(id: $id, title: $title, content: $content) {
      ok
      err
    }
  }
`;

export const ME = gql`
  query Me {
    Me {
      ok
      err
      user {
        id
        email
        emailCode
        verifiedEmail
        password
        firstName
        lastName
        phone
        address
        year
        month
        date
        verfiedPhone
        questions {
          id
          title
          content
          replies {
            id
            content
            createdAt
            updatedAt
          }
          category
          createdAt
          updatedAt
        }
        replies {
          id
          content
          questionId
          createdAt
          updatedAt
        }
        lab_id
        lecture {
          id
          title
          content
          category
          maximum
          periodFrom
          periodTo
          classRoom
          thumbnail
          files {
            url
            fileName
          }
          createdAt
          updatedAt
        }
        lectureId
        booking {
          id
          status
          lab {
            id
            address
          }
          labId
          from
          to
          createdAt
          updatedAt
        }
        lectureBooking {
          id
          status
          lecture_id
          lecture_name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const ADD_REPLY = gql`
  mutation AddReply($content: String!, $questionId: Int!, $route: String) {
    AddReply(content: $content, questionId: $questionId, route: $route) {
      ok
      err
    }
  }
`;

export const DELETE_REPLY = gql`
  mutation DeleteReply($id: Int!) {
    DeleteReply(id: $id) {
      ok
      err
    }
  }
`;

export const EDIT_REPLY = gql`
  mutation EditReply($id: Int!, $content: String!) {
    EditReply(id: $id, content: $content) {
      ok
      err
    }
  }
`;

export const GET_ALL_REPLIES = gql`
  query GetAllReplies($questionId: Int!, $route: String) {
    GetAllReplies(questionId: $questionId, route: $route) {
      ok
      err
      replies {
        id
        content
        user {
          id
        }
        userId
        questionId
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_BOOKING_BY_ID = gql`
  query GetBookingById($id: Int!, $route: String) {
    GetBookingById(id: $id, route: $route) {
      ok
      err
      booking {
        id
        status
        user {
          id
        }
        lab {
          id
          address
          status
          category
          tenant_id
          responsible
          contact
          createdAt
        }
        from
        to
        createdAt
      }
    }
  }
`;

export const GET_LECTURE_BOOKING_BY_ID = gql`
  query GetLectureBookingById($id: Int!, $route: String) {
    GetLectureBookingById(id: $id, route: $route) {
      ok
      err
      booking {
        id
        status
        user {
          id
        }
        lecture_id
        lecture_name
        createdAt
      }
    }
  }
`;

export const GET_BOOKING_BY_USER_ID = gql`
  query GetBookingByUserId($userId: Int!, $route: String) {
    GetBookingByUserId(userId: $userId, route: $route) {
      ok
      err
      booking {
        id
        status
        from
        to
        createdAt
      }
    }
  }
`;

export const GET_QUESTION_USER_ID = gql`
  query GetQuestionByUserId($userId: Int!) {
    GetQuestionByUserId(userId: $userId) {
      ok
      err
      questions {
        id
        title
        content
        userId
        createdAt
      }
    }
  }
`;

export const SEE_USER_BY_ID = gql`
  query seeUserbyId($id: Int!, $route: String) {
    seeUserbyId(id: $id, route: $route) {
      ok
      err
      user {
        id
        email
        verifiedEmail
        firstName
        lastName
        phone
        address
        year
        month
        date
        lecture {
          id
          title
          content
          category
          maximum
          periodFrom
          periodTo
          classRoom
          thumbnail
          files {
            url
            fileName
          }
        }
        questions {
          id
          title
          content
          category
          replies {
            id
          }
        }
        booking {
          id
          from
          to
          status
        }
        lab_id
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_IMAGE_BY_CATEGORY = gql`
  query GetImageByCategory($category: String!) {
    GetImageByCategory(category: $category) {
      ok
      err
      image {
        id
        category
        url
        fileName
        createdAt
      }
    }
  }
`;

export const GET_LECTURE_BY_CATEGORY = gql`
  query GetLectureByCategory($category: String!) {
    GetLectureByCategory(category: $category) {
      ok
      err
      lecture {
        id
        title
        content
        category
        maximum
        periodFrom
        periodTo
        classRoom
        link
        users {
          id
          firstName
          lastName
        }
        thumbnail
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_LECTURE = gql`
  query GetAllLecture {
    GetAllLecture {
      ok
      err
      lecture {
        id
        title
        category
        content
        maximum
        periodFrom
        thumbnail
        periodTo
        link
        classRoom
        createdAt
      }
    }
  }
`;

export const EDIT_LECTURE = gql`
  mutation EditLecture(
    $id: Int!
    $title: String
    $content: String
    $maximum: String
    $periodFrom: String
    $periodTo: String
    $classRoom: String
    $thumbnail: String
    $link: String
  ) {
    EditLecture(
      id: $id
      title: $title
      content: $content
      maximum: $maximum
      periodFrom: $periodFrom
      periodTo: $periodTo
      classRoom: $classRoom
      thumbnail: $thumbnail
      link: $link
    ) {
      ok
      err
    }
  }
`;

export const DELETE_LECTURE = gql`
  mutation DeleteLecture($id: Int!) {
    DeleteLecture(id: $id) {
      ok
      err
    }
  }
`;

export const GET_LECTURE = gql`
  query GetLecture($id: Int!) {
    GetLecture(id: $id) {
      ok
      err
      lecture {
        id
        title
        content
        category
        maximum
        periodFrom
        periodTo
        classRoom
        link
        users {
          id
          firstName
          lastName
          email
          phone
        }
        thumbnail
        createdAt
        files {
          id
          url
          fileName
        }
      }
    }
  }
`;

export const GET_BOOKING_BY_LECTURE_ID = gql`
  query GetBookingByLectureId($lectureId: Int!) {
    GetBookingByLectureId(lectureId: $lectureId) {
      ok
      err
      booking {
        id
        status
        user {
          id
          firstName
          lastName
          phone
          email
        }
        userId
        lecture_id
        createdAt
      }
    }
  }
`;

export const GET_ALL_BOARD = gql`
  query GetAllBoard {
    GetAllBoard {
      ok
      err
      boards {
        id
        title
        content
        hasImages
        imageUrl
        hasFiles
        category
        files {
          url
          fileName
        }
        imageName
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_LAB_BY_CATEGORY = gql`
  query GetLabByCategory($category: String!) {
    GetLabByCategory(category: $category) {
      ok
      err
      lab {
        id
        address
        status
        category
        tenant_id
        booking {
          id
          status
          from
          to
          createdAt
        }
        responsible
        contact
        images {
          id
          url
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_IMAGES = gql`
  query GetAllImage {
    GetAllImage {
      ok
      err
      images {
        id
        url
        boardId
        fileName
        category
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_LABS = gql`
  query GetAllLab {
    GetAllLab {
      ok
      err
      lab {
        id
        address
        status
        category
        tenant_id
        booking {
          id
          userId
          from
          to
          createdAt
        }
        responsible
        contact
        images {
          id
          url
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_LAB_BY_ID = gql`
  query GetLabById($id: Int!) {
    GetLabById(id: $id) {
      ok
      err
      lab {
        id
        address
        status
        category
        tenant_id
        responsible
        contact
        booking {
          id
          userId
          status
          from
          to
          createdAt
        }
        images {
          id
          url
          fileName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const REQUEST_BOOKING = gql`
  mutation RequestBooking(
    $status: String!
    $user_id: Int!
    $username: String!
    $from: String!
    $to: String!
    $lab_id: Int!
    $route: String
  ) {
    RequestBooking(
      status: $status
      user_id: $user_id
      username: $username
      from: $from
      to: $to
      lab_id: $lab_id
      route: $route
    ) {
      ok
      err
    }
  }
`;
export const REQUEST_LECTURE_BOOKING = gql`
  mutation RequestLectureBooking(
    $status: String!
    $user_id: Int!
    $lecture_id: Int!
    $route: String
  ) {
    RequestLectureBooking(
      status: $status
      user_id: $user_id
      lecture_id: $lecture_id
      route: $route
    ) {
      ok
      err
    }
  }
`;

export const EMAIL_DUP_CHECK = gql`
  mutation EmailDupCheck($email: String!) {
    EmailDupCheck(email: $email) {
      ok
      err
    }
  }
`;

export const SEARCH_BOARD_BY_TITLE = gql`
  query SearchBoardByTitle($title: String!, $category: String!) {
    SearchBoardByTitle(title: $title, category: $category) {
      ok
      err
      board {
        id
        title
        content
        hasImages
        imageUrl
        hasFiles
        category
        files {
          url
          fileName
        }
        imageName
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_BOARD_BY_CONTENT = gql`
  query SearchBoardByContent($content: String!, $category: String!) {
    SearchBoardByContent(content: $content, category: $category) {
      ok
      err
      board {
        id
        title
        content
        hasImages
        imageUrl
        hasFiles
        category
        files {
          url
          fileName
        }
        imageName
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_QUESTION_BY_TITLE = gql`
  query SearchQuestionByTitle($title: String!, $category: String!) {
    SearchQuestionByTitle(title: $title, category: $category) {
      ok
      err
      question {
        id
        title
        content
        user {
          id
          firstName
          lastName
        }
        replies {
          id
        }
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const SEARCH_LECTURE_BY_TITLE = gql`
  query SearchLectureByTitle($title: String!, $category: String!) {
    SearchLectureByTitle(title: $title, category: $category) {
      ok
      err
      lecture {
        id
        title
        content
        category
        maximum
        periodFrom
        periodTo
        classRoom
        link
        users {
          id
          firstName
          lastName
          email
          phone
        }
        thumbnail
        createdAt
        files {
          url
          fileName
        }
      }
    }
  }
`;

export const SEARCH_LECTURE_BY_CONTENT = gql`
  query SearchLectureByContent($content: String!, $category: String!) {
    SearchLectureByContent(content: $content, category: $category) {
      ok
      err
      lecture {
        id
        title
        content
        category
        maximum
        periodFrom
        periodTo
        classRoom
        link
        users {
          id
          firstName
          lastName
          email
          phone
        }
        thumbnail
        createdAt
        files {
          url
          fileName
        }
      }
    }
  }
`;

export const SEE_PUBLIC_QUESTION = gql`
  query SeePublicQuestion($id: Int!) {
    SeePublicQuestion(id: $id) {
      ok
      err
      question {
        id
        title
        content
        user {
          id
          firstName
          lastName
        }
        replies {
          id
          content
          questionId
          admin
          user {
            id
            firstName
          }
          userId
          questionId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        userId
      }
    }
  }
`;
