import React from 'react'
import { adminLogOut } from '../../../Apollo/loginResolvers'
import { Header } from '../Style'
import { FiLogOut } from 'react-icons/fi'

interface IProps {
    isLoggedIn: Boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}

const AdminHeader: React.FC<IProps> = ({ isLoggedIn, setIsLoggedIn }) => {
    return (
        <Header.Wrapper>
            <Header.HeaderCol>
                <Header.HeaderA to='/admin/manage/dashboard'>
                    <img src={'/images/vertical_whiteLogo.png'} alt="logo" width={220} />
                </Header.HeaderA>
            </Header.HeaderCol>
            <Header.HeaderCol>
                <Header.HeaderMenu onClick={() => adminLogOut()}>
                    <FiLogOut />&ensp;&ensp;로그아웃
                </Header.HeaderMenu>
            </Header.HeaderCol>
        </Header.Wrapper >
    )
}

export default AdminHeader