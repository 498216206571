import { gql } from "@apollo/client";

export const GET_ALL_BOOKINGS = gql`
  query GetAllBooking {
    GetAllBooking {
      ok
      err
      bookings {
        id
        labAddress
        labId
        username
        userId
        status
        from
        to
      }
    }
  }
`;

export const SEE_ALL_USER = gql`
  query SeeAllUser {
    SeeAllUser {
      ok
      err
      user {
        id
        email
        firstName
        lastName
        phone
        address
        year
        month
        date
        lecture {
          id
          title
          content
          maximum
          periodFrom
          periodTo
          classRoom
          createdAt
        }
        questions {
          id
          title
          content
          createdAt
        }
        replies {
          id
          userId
          questionId
          createdAt
        }
        lab_id
        booking {
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const RESPONSE_BOOKING = gql`
  mutation ResponseBooking($bookingId: Int!, $userId: Int!, $action: String!) {
    ResponseBooking(bookingId: $bookingId, userId: $userId, action: $action) {
      ok
      err
      message
    }
  }
`;

export const RESPONSE_LECTURE_BOOKING = gql`
  mutation ResponseLectureBooking(
    $bookingId: Int!
    $userId: Int!
    $action: String!
  ) {
    ResponseLectureBooking(
      bookingId: $bookingId
      userId: $userId
      action: $action
    ) {
      ok
      err
      message
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation DeleteBooking($id: Int!, $route: String) {
    DeleteBooking(id: $id, route: $route) {
      ok
      err
    }
  }
`;

export const GET_ALL_LECTURE_BOOKING = gql`
  query GetAllLectureBooking {
    GetAllLectureBooking {
      ok
      err
      bookings {
        id
        status
        userId
        username
        lecture_id
        lecture_name
        createdAt
      }
    }
  }
`;
export const CREATE_BOARD = gql`
  mutation CreateBoard(
    $title: String!
    $content: String!
    $image: String
    $hasImages: Boolean!
    $hasFiles: Boolean!
    $category: String!
    $imageName: String!
    $files: [String]
    $fileNames: [String]
  ) {
    CreateBoard(
      title: $title
      content: $content
      image: $image
      hasImages: $hasImages
      hasFiles: $hasFiles
      category: $category
      imageName: $imageName
      files: $files
      fileNames: $fileNames
    ) {
      ok
      err
    }
  }
`;

export const DELETE_BOARD = gql`
  mutation DeleteBoard($id: Int!) {
    DeleteBoard(id: $id) {
      ok
      err
    }
  }
`;

export const CREATE_IMAGE = gql`
  mutation CreateImage($url: String!, $fileName: String!, $category: String!) {
    CreateImage(url: $url, fileName: $fileName, category: $category) {
      ok
      err
    }
  }
`;

export const CREATE_LECTURE = gql`
  mutation CreateLecture(
    $title: String!
    $content: String!
    $category: String!
    $periodFrom: String
    $periodTo: String
    $thumbnail: String!
    $maximum: String
    $link: String
    $files: [String]
    $fileNames: [String]
  ) {
    CreateLecture(
      title: $title
      content: $content
      category: $category
      periodFrom: $periodFrom
      periodTo: $periodTo
      thumbnail: $thumbnail
      maximum: $maximum
      link: $link
      files: $files
      fileNames: $fileNames
    ) {
      ok
      err
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation DeleteImage($id: Int!) {
    DeleteImage(id: $id) {
      ok
      err
    }
  }
`;

export const EDIT_BOARD = gql`
  mutation EditBoard(
    $id: Int!
    $title: String
    $content: String
    $image: String
    $imageName: String
  ) {
    EditBoard(
      id: $id
      title: $title
      content: $content
      image: $image
      imageName: $imageName
    ) {
      ok
      err
      board {
        id
        title
        content
      }
    }
  }
`;

export const EDIT_BOARD_IMAGE = gql`
  mutation EditBoardImage($id: Int!, $url: String!, $fileName: String!) {
    EditBoardImage(id: $id, url: $url, fileName: $fileName) {
      ok
      err
    }
  }
`;

export const DELETE_IMAGE_WITH_BOARDID = gql`
  mutation DeleteImageWithBoardId($boardId: Int!) {
    DeleteImageWithBoardId(boardId: $boardId) {
      ok
      err
    }
  }
`;

export const DELETE_FILE_WITH_BOARDID = gql`
  mutation DeleteFileWithBoardId($boardId: Int!) {
    DeleteFileWithBoardId(boardId: $boardId) {
      ok
      err
    }
  }
`;

export const CREATE_LAB = gql`
  mutation CreateLab(
    $address: String!
    $status: Boolean!
    $category: String!
    $responsible: String!
    $contact: String!
    $image: [String]
    $fileName: [String]
  ) {
    CreateLab(
      address: $address
      status: $status
      category: $category
      responsible: $responsible
      contact: $contact
      image: $image
      fileName: $fileName
    ) {
      ok
      err
    }
  }
`;

export const DELETE_LAB = gql`
  mutation DeleteLab($id: Int!) {
    DeleteLab(id: $id) {
      ok
      err
    }
  }
`;

export const GET_BOOKING_BY_LAB_ID = gql`
  query GetBookingByLabId($id: Int!) {
    GetBookingByLabId(id: $id) {
      ok
      err
      bookings {
        id
        user {
          id
          firstName
          lastName
        }
        userId
        status
        from
        to
        createdAt
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    DeleteUser(id: $id) {
      ok
      err
    }
  }
`;

export const LAB_STATUS_CHANGE = gql`
  mutation LabStatusChange($id: Int!, $status: Boolean!) {
    LabStatusChange(id: $id, status: $status) {
      ok
      err
    }
  }
`;

export const EDIT_LAB = gql`
  mutation EditLab(
    $id: Int!
    $address: String!
    $contact: String!
    $responsible: String!
  ) {
    EditLab(
      id: $id
      address: $address
      contact: $contact
      responsible: $responsible
    ) {
      ok
      err
    }
  }
`;

export const EDIT_IMAGE = gql`
  mutation EditImage($id: Int!, $link: String!) {
    EditImage(id: $id, link: $link) {
      ok
      err
    }
  }
`;

export const EDIT_LAB_IMAGE = gql`
  mutation EditLabImage($id: Int!, $image: String!, $fileName: String!) {
    EditLabImage(id: $id, image: $image, fileName: $fileName) {
      ok
      err
    }
  }
`;

export const DELETE_IMAGE_BY_URL = gql`
  mutation DeleteImagebyUrl($url: String!) {
    DeleteImagebyUrl(url: $url) {
      ok
      err
    }
  }
`;

export const CREATE_FILE = gql`
  mutation CreateFile(
    $url: String!
    $fileName: String!
    $targetId: Int!
    $from: String!
  ) {
    CreateFile(
      url: $url
      fileName: $fileName
      targetId: $targetId
      from: $from
    ) {
      ok
      err
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($id: Int!) {
    DeleteFile(id: $id) {
      ok
      err
    }
  }
`;

export const DELETE_FILE_BY_URL = gql`
  mutation DeleteFileByUrl($url: String!) {
    DeleteFileByUrl(url: $url) {
      ok
      err
    }
  }
`;

export const EDIT_BOARD_FILE = gql`
  mutation EditBoardFile($id: Int!, $url: String!, $fileName: String!) {
    EditBoardFile(id: $id, url: $url, fileName: $fileName) {
      ok
      err
    }
  }
`;

export const RESPONSE_LECTURE_BOOKING_BY_LECTURE_ID = gql`
  mutation ResponseLectureBookingByLectureId(
    $lectureId: Int!
    $userId: Int!
    $action: String!
  ) {
    ResponseLectureBookingByLectureId(
      lectureId: $lectureId
      userId: $userId
      action: $action
    ) {
      ok
      err
    }
  }
`;

export const KICKUSER = gql`
  mutation KickUser($lectureId: Int!, $userId: Int!) {
    KickUser(lectureId: $lectureId, userId: $userId) {
      ok
      err
    }
  }
`;

export const EDIT_LECTURE_FILE = gql`
  mutation EditLectureFile($id: Int!, $url: String!, $fileName: String!) {
    EditLectureFile(id: $id, url: $url, fileName: $fileName) {
      ok
      err
    }
  }
`;
