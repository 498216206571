import styled, { keyframes } from "styled-components";
import InputMask from "react-input-mask";

interface LoadingProps {
  active: number;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const SignUpStyle = {
  Container: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      background-color: white;
      padding: 50px 0;
      width: 100%;
    }
    @media screen and (min-width: 769px) {
      width: 100%;
      height: 100%;
    }
  `,
  SignUpWrapper: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
    }
    @media screen and (min-width: 769px) {
      width: 600px;
      margin: 0 auto;
    }
  `,
  LogoWrapper: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 769px) {
      img {
        width: 400px;
      }
    }
  `,

  BirthWrapper: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 310px;
      display: flex;
      justify-content: space-between;
    }
    @media screen and (min-width: 769px) {
      width: 400px;
    }
  `,
  InputRow: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 300px;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
    @media screen and (min-width: 769px) {
      width: 500px;
      display: flex;
      align-items: center;
    }
  `,
  CheckButton: styled.button`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      border: none;
      border-radius: 6px;
      background-color: #848484;
      padding: 8px 0;
      color: white;
    }
    @media screen and (min-width: 769px) {
      height: 30px;
      margin: 0 15px;
      margin-top: 20px;
      padding: 0 10px;
      border: none;
      border-radius: 4px;
      background-color: ${(props) => props.theme.palette.primary};
      color: white;
      cursor: pointer;
      &:hover {
        background-color: ${(props) => props.theme.palette.secondary};
        transition: 0.2s linear;
      }
    }
  `,
  ApprovedEmail: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
    }
    @media screen and (min-width: 769px) {
      height: 40px;
      margin: 0 15px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      color: #16a085;
    }
  `,
  Input: styled.input`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 300px;
      height: 50px;
      border-radius: 6px;
      border: 1px solid #dedede;
      margin-bottom: 30px;
      padding: 0 20px;
      /* background-color: ${(props) => props.theme.palette.lightgray}; */
    }
    @media screen and (min-width: 769px) {
      width: 350px;
      height: 40px;
      margin-top: 20px;
      padding-left: 10px;
    }
  `,
  InputMask: styled(InputMask)`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 300px;
      height: 50px;
      border-radius: 6px;
      border: 1px solid #dedede;
      margin-bottom: 30px;
      padding: 0 20px;
    }
    @media screen and (min-width: 769px) {
      width: 350px;
      height: 40px;
      margin-top: 20px;
      padding-left: 10px;
    }
  `,

  BirthInput: styled(InputMask)`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      height: 50px;
      border-radius: 6px;
      border: 1px solid #dedede;

      margin-bottom: 30px;
      padding: 0 20px;
      &:first-child {
        width: 120px;
      }
      &:not(:first-child) {
        width: 80px;
      }
    }
    @media screen and (min-width: 769px) {
      width: 110px;
      height: 40px;
      padding-left: 10px;
      margin: 0px 10px 0px 0;
      margin-top: 20px;
      color: #7c7c7c;
    }
  `,
  Form: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    @media screen and (min-width: 769px) {
      width: 50%;
      height: 100%;
      margin: 50px auto;
      display: flex;

      flex-direction: column;
    }
  `,
  SignUpTitle: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      font-size: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    @media screen and (min-width: 769px) {
      font-size: 22px;
      line-height: 2;
      letter-spacing: 0.26px;
      text-align: left;
      color: #04083e;
      margin-top: 2%;
    }
  `,
  Desc: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 230px;
      margin: 15px auto;
      font-size: 18px;
      color: #444444;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (min-width: 769px) {
      font-size: 13px;
      letter-spacing: 0.13px;
      text-align: left;
      color: #444444;
      margin-bottom: 10px;
    }
  `,
  SignUpBtn: styled.button<LoadingProps>`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 300px;
      height: 45px;
      background-color: #04083e;
      border: none;
      border-radius: 4px;
      color: white;
      font-size: 16px;
      box-shadow: 2px 2px 4px 1px #d6d6d6;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 769px) {
      border: none;
      width: 350px;
      height: 45px;
      background-color: #04083e;
      margin-top: 20px;
      color: #ffffff;
      font-size: 13px;
      letter-spacing: 0.16px;
      cursor: pointer;
      opacity: ${(props) => (props.active === 0 ? 1 : 0.5)};
      &:hover {
        background-color: ${(props) =>
          props.active === 0
            ? props.theme.palette.secondary
            : props.theme.palette.primary};
        transition: ${(props) => (props.active === 0 ? "0.2s linear" : "none")};
      }
    }
  `,
  BacktoHome: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      width: 300px;
      color: #444444;
      font-size: 13px;
      margin-top: -10px;
    }
    @media screen and (min-width: 769px) {
      font-size: 13px;
      color: #878787;
      letter-spacing: 0.13px;
      margin-top: 10px;
    }
  `,
  HeaderWrapper: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 769px) {
      background-color: ${(props) => props.theme.palette.primary};
      font-size: 12px;
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10%;
    }
  `,
  HeaderCol: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
    }
    @media screen and (min-width: 769px) {
      color: white;
      display: flex;
    }
  `,
  HeaderButton: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
    }
    @media screen and (min-width: 769px) {
      border: none;
      background-color: transparent;
      color: white;
      width: 60px;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.palette.secondary};
        transition: 0.2s linear;
      }
    }
  `,
  CheckingAni: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
    }
    @media screen and (min-width: 769px) {
    }
  `,
  CheckedButton: styled.button`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
    }
    @media screen and (min-width: 769px) {
      animation: 1s ${fadeIn} ease-out;
    }
  `,
  Label: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
    }
    @media screen and (min-width: 769px) {
      width: 100px;
      height: 20px;
      font-size: 14.5px;
      letter-spacing: 0.13px;
      text-align: left;
      padding-top: 14px;
      color: #7c7c7c;
    }
  `,
  PCHeader: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 769px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  `,
  MobileHeader: styled.div`
    @media only screen and (min-width: 320px) and (max-width: 768px) {
    }
    @media screen and (min-width: 769px) {
      display: none;
    }
  `,
};
