import { HeaderMenuData } from "../../../Components/Header/HeaderData"
import { HomeNavWrapper, HomeNavLogo, HomeNavImage, SubMenu, MenuClick } from "../HomeStyles"
import { forceHistory } from "../../../Hooks/forceHistory"

const HomeNavBar = () => {
	return (
		<HomeNavWrapper>
			<HomeNavLogo>
				<HomeNavImage src={`/images/logo_left.png`} alt="sunmon-logo" />
			</HomeNavLogo>
			<SubMenu>
				{HeaderMenuData.map((data, index) => {
					return (
						<div key={index}>
							<MenuClick
								onClick={() =>
									forceHistory.push(`/${data.param}/${data.category[0].subParam}`)
								}
							>
								{data.title}
							</MenuClick>
						</div>
					)
				})}
			</SubMenu>
		</HomeNavWrapper>
	)
}

export default HomeNavBar
// forceHistory
// to={`/${data.param}/${data.category[0].subParam}`}
