import styled from "styled-components"
import InputMask from "react-input-mask"

export const Border = styled.div`
	@media only screen and (min-width: 320px) and(max-width:767px) {
	}
	@media screen and (min-width: 769px) {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid blueviolet;
	}
`
export const ProfileStyle = {
	BtnWrapper: styled.div`
		@media only screen and (min-width: 320px) and (max-width: 768px) {
			margin: 0 auto;
			margin-bottom: 30px;
		}
		@media screen and (min-width: 769px) {
			width: 150%;
			height: 100%;

			display: flex;
			justify-content: center;
			align-items: center;
			margin: 20px 0;
		}
	`,

	ListBtn: styled.button`
		@media only screen and (min-width: 320px) and (max-width: 768px) {
			border: none;
			border-radius: 4px;
			width: 95px;
			height: 45px;
			background-color: #8b8b8b;
			color: #ffffff;
			font-size: 16px;
			line-height: 2.2;
			letter-spacing: 0.28px;
		}
		@media screen and (min-width: 769px) {
			border: none;
			width: 120px;
			height: 50px;
			background-color: #ec008c;

			color: #ffffff;
			font-size: 16px;
			line-height: 2.2;
			letter-spacing: 0.28px;
			cursor: pointer;

			&:hover {
				opacity: 0.5;
				transition: 0.2s linear;
			}
		}
	`,
	ModifyButton: styled.button`
		@media only screen and (min-width: 320px) and (max-width: 768px) {
			width: 195px;
			height: 45px;
			border: none;
			border-radius: 4px;
			background-color: #04083e;
			font-size: 16px;
			line-height: 2.2;
			letter-spacing: 0.28px;
			color: #ffffff;
			margin-right: 10px;
		}
		@media screen and (min-width: 769px) {
			width: 320px;
			height: 50px;
			border: none;
			background-color: #04083e;
			font-size: 16px;
			line-height: 2.2;
			letter-spacing: 0.28px;
			color: #ffffff;
			margin-right: 20px;
			cursor: pointer;
			&:hover {
				opacity: 0.5;
				transition: 0.2s linear;
			}
		}
	`,
	Input: styled.input`
		@media only screen and (min-width: 320px) and (max-width: 768px) {
			width: 300px;
			height: 50px;
			border-radius: 6px;
			border: 1px solid #dedede;
			margin-bottom: 30px;
			/* box-shadow: 2px 2px 2px 1px #e6ecf4; */
			padding: 0 20px;
		}
		@media screen and (min-width: 769px) {
			width: 350px;
			height: 40px;
			margin-top: 20px;
			padding-left: 10px;
		}
	`,
	InputMask: styled(InputMask)`
		@media only screen and (min-width: 320px) and (max-width: 768px) {
			width: 300px;
			height: 50px;
			border-radius: 6px;

			margin-bottom: 30px;
			border: 1px solid #dedede;
			/* box-shadow: 2px 2px 2px 1px #e6ecf4; */
			padding: 0 20px;
		}
		@media screen and (min-width: 769px) {
			width: 350px;
			height: 40px;
			margin-top: 20px;
			padding-left: 10px;
		}
	`,

	BirthInput: styled(InputMask)`
		@media only screen and (min-width: 320px) and (max-width: 768px) {
			height: 50px;
			border-radius: 6px;
			border: 1px solid #dedede;
			margin-bottom: 30px;
			/* box-shadow: 2px 2px 2px 1px #e6ecf4; */
			padding: 0 20px;
			&:first-child {
				width: 105px;
			}
			&:not(:first-child) {
				width: 85px;
			}
		}
		@media screen and (min-width: 769px) {
			width: 110px;
			height: 40px;
			padding-left: 10px;
			margin: 0px 10px 0px 0;
			margin-top: 20px;
			color: #7c7c7c;
		}
	`,

	InputRow: styled.div`
		@media only screen and (min-width: 320px) and (max-width: 768px) {
			width: 300px;
			display: flex;
			align-items: center;
			margin-bottom: 10px;
		}
		@media screen and (min-width: 769px) {
			width: 500px;
			display: flex;
			align-items: center;
		}
	`,
	Label: styled.div`
		@media only screen and (min-width: 320px) and (max-width: 768px) {
			display: none;
		}
		@media screen and (min-width: 769px) {
			width: 100px;
			height: 20px;
			font-size: 14.5px;
			letter-spacing: 0.13px;
			text-align: left;
			padding-top: 14px;
			color: #7c7c7c;
		}
	`,
}
