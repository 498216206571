import { Link } from "react-router-dom";
import styled from "styled-components";

interface ActiveProps {
  active: number;
}

export const Wrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    background-color: white;
  }
  @media screen and (min-width: 769px) {
    min-width: 1281px;
    max-width: 1980px;
    background-color: white;
  }
`;

export const MainWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    background-color: white;
  }
  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #04083e;
    width: 100%;
    min-width: 1281px;
  }
`;

export const DetailWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 1281px;
    margin-bottom: 30px;
  }
`;

export const Input = styled.input`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 310px;
    height: 50px;
    border-radius: 6px;
    border: 1px solid #dedede;

    margin-bottom: 30px;
    padding: 0 20px;
    /* background-color: ${(props) => props.theme.palette.lightgray}; */
    /* padding: 4px;
		background-color: gray;
		width: 100px;
		height: 50px; */
  }
  @media screen and (min-width: 769px) {
  }
`;

export const Button = styled.input`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media screen and (min-width: 769px) {
    border: none;
    border-radius: 3px;
    margin-top: 5px;
    color: black;
    text-align: center;
    padding: 8px 2px;
    font-weight: 600;
    opacity: ${(props) => (props.disabled ? "0.2" : "1")};
  }
`;

export const BButton = styled.button`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    border: none;
    border-radius: 3px;
    margin: 5px 7px;
    color: black;
    text-align: center;
    padding: 8px 2px;
    font-weight: 600;
  }
`;

export const DefaultButton = styled.button`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: block;
    width: 70px;
    height: 30px;
    line-height: 30px;
    border: 1px #04083e solid;
    margin: 5px;
    margin-bottom: -20px;
    background-color: #04083e;
    text-align: center;
    cursor: pointer;
    color: #fff;
    transition: all 0.9s, color 0.3;
    &:hover {
      color: #ec008c;
    }
  }
`;

export const TitleWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (min-width: 769px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 0 0;
  }
`;

export const TitleRow = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media screen and (min-width: 769px) {
    height: 120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px;
  }
`;

export const TitleBKRow = styled.div`
  height: 2px;
  margin-bottom: 10px;
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 330px;
    background-color: ${(props) => props.theme.palette.primary};
  }
  @media screen and (min-width: 769px) {
    width: 99%;
    display: flex;
    background-color: black;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TitleGrayRow = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media screen and (min-width: 769px) {
    display: flex;
    height: 1px;
    width: 100%;
    background-color: darkgray;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TitleLogo = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
  @media screen and (min-width: 769px) {
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Title = styled.h1`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media screen and (min-width: 769px) {
    font-size: 33px;
    line-height: 92.5px;
    word-spacing: 0.37px;
    text-align: center;
    color: #04083e;
  }
`;

export const MobileTitle = styled.h1`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 17px;
    color: #04083e;
    line-height: 1.5;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const Container = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 375px;
    margin: 0 auto;
    padding: 45px 25px;
    min-height: 72vh;
  }
  @media screen and (min-width: 769px) {
    width: 1100px;
    margin: 0 auto;
  }
`;
export const MiddleMenuWrapper = styled.div`
  border-top: 2px solid #04083e;
  border-bottom: 2px solid #04083e;
  padding: 10px 0px;
`;

export const MenuRow = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  @media only screen and (min-width: 769px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const MenuCol = styled.div``;

export const MenuLink = styled(Link)`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    font-size: 20px;
    line-height: 49px;
    word-spacing: -0.54px;
    margin-right: 50px;
    &:hover {
      transition: 0.2s linear;
      color: #ec008c;
      font-weight: 800;
    }
  }
`;

export const MenuRoute = styled.div<ActiveProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 13px;
    margin-right: 20px;
    text-align: center;
    color: ${(props) =>
      props.active === 1 ? props.theme.palette.secondary : "black"};
  }
  @media only screen and (min-width: 769px) {
    font-size: 20px;
    line-height: 49px;
    word-spacing: -0.54px;
    margin-right: 50px;
    margin-left: 20px;
    color: ${(props) =>
      props.active === 1 ? props.theme.palette.secondary : "black"};
    cursor: pointer;
    &:hover {
      transition: 0.2s linear;
      color: ${(props) => props.theme.palette.secondary};
      font-weight: 800;
    }
  }
`;

export const SecondMenuRoute = styled.div<ActiveProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 13px;
    margin-right: 20px;
    text-align: center;
    color: ${(props) =>
      props.active === 1 ? props.theme.palette.secondary : "black"};
  }
  @media only screen and (min-width: 769px) {
    font-size: 20px;
    line-height: 49px;
    word-spacing: -0.54px;
    margin-left: 20px;
    margin-right: 50px;
    color: ${(props) =>
      props.active === 1 ? props.theme.palette.secondary : "black"};
    cursor: pointer;
    &:hover {
      transition: 0.2s linear;
      color: ${(props) => props.theme.palette.secondary};
      font-weight: 800;
    }
  }
`;
