import { useState } from 'react'
import { toast } from 'react-toastify'
import useInput from '../../Hooks/useInput'
import { useParams } from 'react-router'
import { Container, Content, Login, Section, Wrapper } from './Style'
import AdminHeader from './components/AdminHeader'
import AdminNavigation from './components/Navigation'
import PageDispatch from './components/PageDispatch'
import { adminLogin } from '../../Apollo/loginResolvers'
import { useReactiveVar } from '@apollo/client'
import { adminLoginVar } from '../../Apollo/apollo'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


interface IPramsProps {
    param: string
    subparam: string
}

const Admin = () => {
    const loginStatus = useReactiveVar(adminLoginVar)
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
    const [adminId, adminIdOnChange, setAdminId] = useInput('')
    const [adminPW, adminPWOnChange, setAdminPW] = useInput('')
    const { param, subparam }: IPramsProps = useParams()

    const loginHandler = () => {
        if (adminId === 'adminsw' && adminPW === 'adminsw') {
            setIsLoggedIn(true)
            setAdminId('')
            setAdminPW('')
            adminLogin()
            toast.success('로그인 되었습니다')
        } else {
            toast.error('암호가 틀립니다.')
        }
    }

    return (
        <Wrapper>
            {loginStatus ?
                <>
                    <AdminHeader isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                    <Container>
                        <Section>
                            <AdminNavigation />
                        </Section>
                        <Section>
                            <Content>
                                <PageDispatch param={param} subParam={subparam} />
                            </Content>
                        </Section>
                    </Container>
                </>
                :
                <Login.Container>
                    <Login.Title>Admin Login</Login.Title>
                    <Login.Box>
                        <Login.Input placeholder={'아이디를 입력해 주세요'} value={adminPW} onChange={adminPWOnChange} autoFocus={true} />
                        <Login.Input placeholder={'비밀번호를 입력해 주세요'} type="password" value={adminId} onChange={adminIdOnChange} />
                        <Login.Button type="submit" onClick={loginHandler}>로그인</Login.Button>
                    </Login.Box>
                </Login.Container>
            }
        </Wrapper>
    )
}

export default Admin