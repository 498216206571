export const HeaderMenuData = [
	{
		title: "SW융합교육원소개",
		param: "intro",
		category: [
			{
				title: "소개",
				param: "intro",
				subParam: "intro",
			},
			{
				title: "조직도",
				param: "intro",
				subParam: "contact",
			},
			{
				title: "찾아오시는길",
				param: "intro",
				subParam: "route",
			},
			{
				title: "SW중심대학사업",
				param: "intro",
				subParam: "business",
			},
		],
	},
	{
		title: "Today's News",
		param: "news",
		category: [
			{
				title: "NEWS",
				param: "news",
				subParam: "news",
			},
		],
	},

	{
		title: "SW비교과프로그램",
		param: "program",
		category: [
			{
				title: "비교과소개",
				param: "program",
				subParam: "p-intro",
			},
			{
				title: "수강신청",
				param: "program",
				subParam: "p-apply",
			},
		],
	},
	{
		title: "국비지원사업",
		param: "aid",
		category: [
			{
				title: "국비지원소개",
				param: "aid",
				subParam: "a-intro",
			},
			{
				title: "수강신청",
				param: "aid",
				subParam: "a-apply",
			},
		],
	},
	{
		title: "창업지원프로그램",
		param: "startup",
		category: [
			{
				title: "창업지원소개",
				param: "startup",
				subParam: "s-intro",
			},
			{
				title: "수강신청",
				param: "startup",
				subParam: "s-apply",
			},
		],
	},
	{
		title: "‎‎‎‎전산실습실예약",
		param: "reservation",
		category: [
			{
				title: "예약하기",
				param: "reservation",
				subParam: "lab",
			},
		],
	},
	{
		title: '자료실',
		param: 'storage',
		category: [
			{
				title: '비교과자료실',
				param: 'storage',
				subParam: 'pStorage'
			},
			{
				title: '국비지원자료실',
				param: 'storage',
				subParam: 'aStorage'
			},
			{
				title: '창업지원자료실',
				param: 'storage',
				subParam: 'sStorage'
			},
			{
				title: '선문융합연구회',
				param: 'storage',
				subParam: 'mstorage'
			},
		]
	},
	{
		title: "커뮤니티",
		param: "board",
		category: [
			{
				title: "공지사항",
				param: "board",
				subParam: "notice",
			},
			{
				title: "건의사항",
				param: "board",
				subParam: "forum",
			},
			{
				title: "Q & A",
				param: "board",
				subParam: "qa",
			},
		],
	},

]

export const HeaderSubMenu = [
	{
		data: [
			{
				title: "SW융합교육원소개",
				param: "intro",
				subParam: "intro",
			},
			{
				title: "소개",
				param: "intro",
				subParam: "intro",
			},
			{
				title: "조직도",
				param: "intro",
				subParam: "contact",
			},
			{
				title: "찾아오시는길",
				param: "intro",
				subParam: "route",
			},
			{
				title: "SW중심대학사업",
				param: "intro",
				subParam: "business",
			},
		],
	},
	{
		data: [
			{
				title: "Today's News",
				param: "news",
				subParam: "news",
			},
			{
				title: "NEWS",
				param: "news",
				subParam: "news",
			},
		],
	},

	{
		data: [
			{
				title: "SW비교과프로그램",
				param: "program",
				subParam: "p-intro",
			},

			{
				title: "비교과소개",
				param: "program",
				subParam: "p-intro",
			},
			{
				title: "수강신청",
				param: "program",
				subParam: "p-apply",
			},
		],
	},
	{
		data: [
			{
				title: "국비지원사업",
				param: "aid",
				subParam: "a-intro",
			},
			{
				title: "국비지원소개",
				param: "aid",
				subParam: "a-intro",
			},
			{
				title: "수강신청",
				param: "aid",
				subParam: "a-apply",
			},
		],
	},
	{
		data: [
			{
				title: "창업지원프로그램",
				param: "startup",
				subParam: "s-intro",
			},
			{
				title: "창업지원소개",
				param: "startup",
				subParam: "s-intro",
			},
			{
				title: "수강신청",
				param: "startup",
				subParam: "s-apply",
			},
		],
	},

	{
		data: [
			{
				title: "전산실습실예약",
				param: "reservation",
				subParam: "lab",
			},
			{
				title: "예약하기",
				param: "reservation",
				subParam: "lab",
			},
		],
	},
	{
		data: [
			{
				title: '자료실',
				param: 'storage',
				subParam: 'pStorage'
			},
			{
				title: '비교과자료실',
				param: 'storage',
				subParam: 'pStorage'
			},
			{
				title: '국비지원자료실',
				param: 'storage',
				subParam: 'aStorage'
			},
			{
				title: '창업지원자료실',
				param: 'storage',
				subParam: 'sStorage'
			},
			{
				title: '선문융합연구회',
				param: 'storage',
				subParam: 'mstorage'
			},
		]
	},
	{
		data: [
			{
				title: "커뮤니티",
				param: "board",
				subParam: "notice",
			},
			{
				title: "공지사항",
				param: "board",
				subParam: "notice",
			},
			{
				title: "건의사항",
				param: "board",
				subParam: "forum",
			},
			{
				title: "Q & A",
				param: "board",
				subParam: "qa",
			},
		],
	},
]


export const MiddleBannerSubMenu = [
	{
		data: [
			{
				title: "소개",
				param: "intro",
				subParam: "intro",
			},
			{
				title: "조직도",
				param: "intro",
				subParam: "contact",
			},
			{
				title: "찾아오시는길",
				param: "intro",
				subParam: "route",
			},
			{
				title: "SW중심대학사업",
				param: "intro",
				subParam: "business",
			},
		],
	},
	{
		data: [
			{
				title: "NEWS",
				param: "news",
				subParam: "news",
			},
		],
	},

	{
		data: [
			{
				title: "비교과소개",
				param: "program",
				subParam: "p-intro",
			},
			{
				title: "수강신청",
				param: "program",
				subParam: "p-apply",
			},
		],
	},
	{
		data: [
			{
				title: "국비지원소개",
				param: "aid",
				subParam: "a-intro",
			},
			{
				title: "수강신청",
				param: "aid",
				subParam: "a-apply",
			},
		],
	},
	{
		data: [
			{
				title: "창업지원소개",
				param: "startup",
				subParam: "s-intro",
			},
			{
				title: "수강신청",
				param: "startup",
				subParam: "s-apply",
			},
		],
	},

	{
		data: [
			{
				title: "예약하기",
				param: "reservation",
				subParam: "lab",
			},
		],
	},
	{
		data: [
			{
				title: "비교과자료실",
				param: "storage",
				subParam: "pStorage",
			},
			{
				title: "국비지원자료실",
				param: "storage",
				subParam: "aStorage",
			},
			{
				title: "창업지원자료실",
				param: "storage",
				subParam: "sStorage",
			},
			{
				title: "선문융합연구회",
				param: "storage",
				subParam: "mstorage",
			},
		],
	},
	{
		data: [
			{
				title: "공지사항",
				param: "board",
				subParam: "notice",
			},
			{
				title: "건의사항",
				param: "board",
				subParam: "forum",
			},
			{
				title: "Q & A",
				param: "board",
				subParam: "qa",
			},
		],
	},
]