import loadable from "@loadable/component";

const DashBoard = loadable(() => import('../AdminRoutes/Manage/DashBoard'))
const AdminAccountManage = loadable(() => import('../AdminRoutes/Manage/AdminAccountManage'))
const AdminContact = loadable(() => import('../AdminRoutes/Intro/AdminContact'))
const AdminIntro = loadable(() => import('../AdminRoutes/Intro/AdminIntro'))
const AdminNews = loadable(() => import('../AdminRoutes/News/AdminNews'))
const AdminQuestionManage = loadable(() => import('../AdminRoutes/Manage/AdminQuestionManage'))
const AdminProgramManage = loadable(() => import('../AdminRoutes/Program/manage/ProgramManage'))
const AdminKtrainingManage = loadable(() => import('../AdminRoutes/Aid/manage/AdminKtrainingManage'))
const AdminStartUpManage = loadable(() => import('../AdminRoutes/Startup/manage/AdminStartUpManage'))
const AdminNoticeManage = loadable(() => import('../AdminRoutes/Manage/AdminNoticeManage'))
const AdminStorageManage = loadable(() => import('../AdminRoutes/Manage/AdminStorageManage'))
const AdminManageWrite = loadable(() => import('../AdminRoutes/Manage/write/AdminManageWrite'))
const AdminCreateLab = loadable(() => import('../AdminRoutes/Reservation/write/AdminCreateLab'))
const AdminLabManage = loadable(() => import('../AdminRoutes/Reservation/AdminLabManage'))
const AdminLabBooking = loadable(() => import('../AdminRoutes/Reservation/AdminLabBooking'))
const AdminReportManage = loadable(() => import('../AdminRoutes/Manage/AdminReportManage'))
const AdminAidDetail = loadable(() => import('../AdminRoutes/Aid/detail/AdminAidDetail'))
const AdminCreateAidImage = loadable(() => import('../AdminRoutes/Aid/image/AdminCreateAidImage'))
const AdminSWBusiness = loadable(() => import('../AdminRoutes/Intro/AdminSWBusiness'))
const UserDetail = loadable(() => import('../AdminRoutes/Manage/detail/UserDetail'))
const QuestionDetail = loadable(() => import('../AdminRoutes/Manage/detail/QuestionDetail'))
const NoticeDetail = loadable(() => import('../AdminRoutes/Manage/detail/NoticeDetail'))
const StorageDetail = loadable(() => import('../AdminRoutes/Manage/detail/StorageDetail'))
const ReportDetail = loadable(() => import('../AdminRoutes/Manage/detail/ReportDetail'))
const AdminManageEdit = loadable(() => import('../AdminRoutes/Manage/edit/AdminManageEdit'))
const NewsDetail = loadable(() => import('../AdminRoutes/News/detail/NewsDetail'))
const NewsWrite = loadable(() => import('../AdminRoutes/News/write/NewsWrite'))
const ProgramDetail = loadable(() => import('../AdminRoutes/Program/detail/AdminProgramDetail'))
const AdminCreateProgramImage = loadable(() => import('../AdminRoutes/Program/image/AdminCreateProgramImage'))
const AdminLabDetail = loadable(() => import('../AdminRoutes/Reservation/detail/AdminLabDetail'))
const AdminCreateStartupImage = loadable(() => import('../AdminRoutes/Startup/image/AdminCreateStartupImage'))


export const RouteHandler = (param: string, subParam: string) => {
  switch (param) {
    case 'manage':
      if (subParam === 'dashboard')
        return <DashBoard />
      else if (subParam === 'account')
        return <AdminAccountManage />
      else if (subParam === 'question')
        return <AdminQuestionManage />
      else if (subParam === 'notice')
        return <AdminNoticeManage />
      else if (subParam === 'storage')
        return <AdminStorageManage />
      else if (subParam === 'report')
        return <AdminReportManage />
      else if (subParam === 'write')
        return <AdminManageWrite />
      else if (subParam === 'edit')
        return <AdminManageEdit />
      else
        return;
    case "intro":
      if (subParam === "intro")
        return <AdminIntro />
      else if (subParam === 'contact')
        return <AdminContact />
      else if (subParam === 'business')
        return <AdminSWBusiness />
      else
        return;
    case "news":
      if (subParam === 'news')
        return <AdminNews />
      else if (subParam === 'write')
        return <NewsWrite />
      else
        return;
    case "program":
      if (subParam === 'pmanage')
        return <AdminProgramManage />
      else if (subParam === 'image')
        return <AdminCreateProgramImage />
      else
        return;
    case "startup":
      if (subParam === 'smanage')
        return <AdminStartUpManage />
      else if (subParam === 'simage')
        return <AdminCreateStartupImage />
      else
        return;
    case "aid":
      if (subParam === 'kmanage')
        return <AdminKtrainingManage />
      else if (subParam === 'aimage')
        return <AdminCreateAidImage />
      else
        return;
    case "reservation":
      if (subParam === 'lab')
        return <AdminLabManage />
      else if (subParam === 'write')
        return <AdminCreateLab />
      else if (subParam === 'booking')
        return <AdminLabBooking />

      else
        return;

    case "users":
      return <UserDetail userId={subParam} />
    case "question":
      return <QuestionDetail questionId={subParam} />
    case "report":
      return <ReportDetail reportId={subParam} />
    case "newsDetail":
      return <NewsDetail newsId={subParam} />
    case "noticeDetail":
      return <NoticeDetail noticeId={subParam} />
    case "storageDetail":
      return <StorageDetail storageId={subParam} />
    case 'programDetail':
      return <ProgramDetail />
    case 'labDetail':
      return <AdminLabDetail labId={subParam} />
    case 'aidDetail':
      return <AdminAidDetail aidId={subParam} />

    default:
      return;
  }
};
