import { Link } from "react-router-dom";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";

interface IStyle {
  url: string;
  showing?: boolean;
}

interface IDot {
  current: boolean;
}

interface IProps {
  hasTheme?: boolean;
}

interface IActive {
  active: number;
}

interface ShowProps {
  show: boolean;
}

export const HomeBanner = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    display: none;
    /* height: 280px; */
  }
  @media screen and (min-width: 769px) {
    max-width: 1980px;
    width: 100%;
    position: relative;
  }
`;

export const MobileBanner = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const ExtendedCarousel = styled(Carousel)`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    /* height: 400px; */
  }
  @media screen and (min-width: 769px) {
    max-width: 1980px;
    width: 100%;
    position: relative;
  }
`;

export const ImageBox = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 769px) {
    height: 89vh;
  }
`;

export const BannerImage = styled.img`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  @media screen and (min-width: 769px) {
    object-fit: cover;
    object-position: center;
    height: 100vh;
  }
`;

export const ImageText = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    position: absolute;
    z-index: 1;
    line-height: 1;
    letter-spacing: -0.89px;
    color: #ffffff;
    padding-left: 130px;
    bottom: 100px;
  }
`;

export const BannerTitle = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    font-size: 75px;
    letter-spacing: 0.5px;
    line-height: 1;
    font-family: AppleMedium;
    font-weight: 600;
  }
`;

export const TopBanner = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    width: 300px;
    height: 23px;
    margin: 0 459px 31px 7px;
    font-family: AppleMedium;
    font-size: 27px;
    line-height: 0.76;
    letter-spacing: 0.29px;
  }
`;

export const BottomBanner = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    width: 800px;
    height: 26px;
    margin: 46px 0 0 3px;
    font-size: 21px;
    line-height: 1.4;
    letter-spacing: 0.28px;
    font-family: AppleMedium;
  }
`;

export const Dots = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    position: absolute;
    z-index: 1;
    margin: 0 0 22px 35px;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    right: 12%;
    bottom: 3%;
  }
`;

export const Dot = styled.button<IDot>`
  border: none;
  background-color: ${(props) => (props.current ? "white" : "#cccccc")};
  border-radius: 100%;
  cursor: pointer;
  margin: 8%;
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 8px;
    height: 8px;
  }
  @media only screen and (min-width: 769px) {
    height: 12px;
    width: 12px;
  }
`;

export const HomeContainer = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    width: 100%;
    max-width: 1980px;
    justify-content: center;
  }
`;

export const LeftContainer = styled.div<ShowProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    width: 20%;
    height: 100%;
    overflow: unset;
    top: 50px;
    position: ${(props) => (props.show ? "sticky" : "relative")};
    padding-top: ${(props) => (props.show ? "50px" : "0px")};
  }
`;

export const RightContainer = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
`;

export const NavBar = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    background-color: #04083e;
    height: 600px;
    color: white;
  }
`;

export const FirstBoard = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    width: 100%;
    /* max-width: 1400px; */
    background-color: #e6e7ec;
    padding: 0px 20px;
    min-height: 400px;
  }
`;

export const SecondBoard = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    padding-bottom: 80px;
    /* min-height: 420px; */
    background-color: white;
    width: 100%;
    /* max-width: 1400px; */
  }
`;

export const ThirdBoard = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    background-color: white;
    width: 100%;
    /* max-width: 1400px; */
    padding-bottom: 160px;
  }
`;
export const FourthBoard = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    justify-content: center;
    height: 420px;
    background-color: white;
    width: 100%;
    /* max-width: 1400px; */
  }
`;
export const FifthBoard = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    height: 150px;
    background-color: white;
    width: 100%;
    max-width: 1400px;
  }
`;

export const HomeNavLogo = styled.div``;

export const HomeNavWrapper = styled.div`
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const HomeNavImage = styled.img`
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    width: 70%;
    margin: 0 auto;
    margin-left: -30px;
  }
`;
export const SubMenu = styled.div`
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    width: 70%;
    margin-top: 15px;
  }
`;
export const MenuLink = styled(Link)`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    font-size: 12px;
    line-height: 42px;
    font-weight: 800;
    word-spacing: 0.18px;
    &:hover {
      transition: 0.2s linear;
      color: #ec008c;
      /* text-decoration: underline; */
    }
  }
`;
export const MenuClick = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    font-size: 14px;
    line-height: 42px;
    font-weight: 800;
    word-spacing: 0.18px;
    cursor: pointer;
    &:hover {
      transition: 0.2s linear;
      color: #ec008c;
      /* text-decoration: underline; */
    }
  }
`;

export const Title = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    color: #04083e;
    font-size: 15pt;
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 769px) {
    /* width: 50%; */
    font-size: 20px;

    line-height: 1.88;
    letter-spacing: 0.24px;
    text-align: left;
    color: #04083e;
    /* margin-left: 1%; */
  }
`;
export const SubTitle = styled.div<IActive>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 50px;
    font-size: 13px;
    margin-right: 16px;
    line-height: 10px;

    color: ${(props) =>
      props.active === 0
        ? props.theme.palette.primary
        : props.theme.palette.secondary};
    &:hover {
      color: ${(props) => props.theme.palette.secondary};
      transition: 0.2s linear;
    }
  }

  @media only screen and (min-width: 769px) {
    width: 15%;
    margin-right: 2%;
    font-family: AppleMedium;
    font-size: 14px;
    cursor: pointer;
    line-height: 2.56;
    letter-spacing: -0.98px;
    color: ${(props) =>
      props.active === 0
        ? props.theme.palette.primary
        : props.theme.palette.secondary};
    &:hover {
      color: ${(props) => props.theme.palette.secondary};
      transition: 0.2s linear;
    }
  }
`;

export const SubContent = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    width: 330px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: -0.7px;
  }
  @media only screen and (min-width: 769px) {
    padding: 4px 8px;
    padding-left: 0px;
    font-size: 14px;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const SubDate = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    padding: 4px 15px;
    font-size: 14px;
    margin-left: 20px;
  }
`;

export const NewsDesc = styled.div<IProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 180px;
    height: 49px;
    line-height: 1.4;
    font-size: 12px;
    color: #606060;
    margin-top: 5px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis;
    white-space: wrap;
  }
  @media only screen and (min-width: 769px) {
    font-size: 14px;
    line-height: 1.55;
    margin: 8px 0;
    width: 250px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis;
    white-space: wrap;
  }
`;

export const Desc = styled.div<IProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 10px;
    width: 280px;
    overflow: hidden;
    font-size: 20px;
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media only screen and (min-width: 769px) {
    font-size: 14px;
    line-height: 1.55;
    letter-spacing: -0.83px;
    margin: 8px 0;
    width: 200px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const MoreInfo = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    font-size: 14px;
    line-height: 1.55;
    letter-spacing: -0.83px;
    color: #606060;
  }
`;
/*=============HomeNotice============= */

export const SubTitles = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 769px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    line-height: 2.56;
    letter-spacing: -0.98px;
  }
`;
export const NoticeWrapper = styled.div`
  /* margin: 1% 0 5% 1%; */
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
  }
`;

export const NoticeRow = styled.div<IActive>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 300px;
    margin: 10px 0;
  }
  @media only screen and (min-width: 769px) {
    cursor: pointer;
    /* padding: 0px 12px; */
    min-width: 440px;
    margin: 4px 0 4px 0;
    background-color: ${(props) =>
      props.active === 0 ? "white" : "transparent"};
    &:hover {
      color: ${(props) => props.theme.palette.secondary};
      transition: 0.2s linear;
    }
  }
`;

export const NoticeDesc = styled.div<IProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    /* justify-content: space-between; */
    font-size: 14px;
    line-height: 2;
    letter-spacing: -0.83px;
    color: ${(props) => (props.hasTheme ? "#ffffff" : "#000000")};
    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.palette.secondary};
      transition: 0.2s linear;
    }
  }
`;
export const NoticeMoreInfo = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 11px;
    margin-top: 20px;
  }
  @media only screen and (min-width: 769px) {
    font-size: 14px;
    line-height: 1.55;
    letter-spacing: -0.83px;
    color: #606060;
    cursor: pointer;
    margin-top: 20px;
    &:hover {
      color: ${(props) => props.theme.palette.primary};
      transition: 0.2s linear;
    }
  }
`;

/*=============HomeEvent============= */
export const NewsWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    height: 350px;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    padding: 30px 0;
  }
`;

export const NewsRow = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    height: 75px;
    margin: 10px 0;
  }
  @media only screen and (min-width: 769px) {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px 35px 8px 0;
  }
`;

export const NewsConWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    /* align-items: center; */
    height: 75px;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
  }
`;

export const NewsDescWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    height: 50px;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    padding: 6px 10px;
    margin-left: 10px;
  }
`;

export const NewsImg = styled.img`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100px;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin-right: 15px;
  }
  @media only screen and (min-width: 769px) {
    height: 120px;
    width: 180px;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
  }
`;
export const NewsTitle = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 180px;
    font-size: 14px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media only screen and (min-width: 769px) {
    font-family: AppleMedium;
    width: 250px;
    overflow: hidden;
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const NewsTitleSpan = styled.span`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    color: ${(props) => props.theme.palette.primary};
  }
  @media only screen and (min-width: 769px) {
    font-family: AppleMedium;
    width: 350px;
  }
`;

/*=============HomePrograms============= */

export const HomeProgWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
  }
`;

export const ProgramImage = styled.img`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    width: 100%;
    height: 250px;
    margin-right: -50px;
    object-position: center;
    object-fit: contain;
  }
`;

export const DescWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    font-size: 14px;
    color: #000000;
    line-height: 1.26;
    letter-spacing: 0.17px;
    margin: 0 10px;
  }
`;
/*=============HomeK-training============= */

export const HomeKtWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    padding: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 85%;
    max-width: 1100px;
    /* margin: 0 auto; */
    margin-left: 30px;
    padding-bottom: 100px;
    border-bottom: 0.5px dashed #c5c7c4;
  }
`;
export const HeaderWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    /* width: 100%; */
    width: 300px;
    justify-content: space-between;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    width: 780px;
    margin: 15px 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const KtWrappers = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: row;
  }
`;

export const KtWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 300px;
  }
  @media only screen and (min-width: 769px) {
    width: 240px;
    height: 240px;
    margin: 5px 25px;
    cursor: pointer;
    &:hover {
      background-color: ${(props) => props.theme.palette.gray};
      transition: 0.2s linear;
    }
  }
`;

export const KtWrapperWD = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    width: 240px;
    height: 300px;
    margin: 5px 25px;
    cursor: pointer;
  }
`;

export const KtImage = styled.img`
  object-fit: cover;
  object-position: center;
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 290px;
    height: 290px;
  }
  @media only screen and (min-width: 769px) {
    width: 100%;
    height: 240px;
    margin-right: -50px;
  }
`;

export const BKDesc = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    padding: 10px;
  }
  @media only screen and (min-width: 769px) {
    height: 40%;
    margin-top: -1%;
    padding: 8px;
  }
`;

export const BKDescBlack = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 290px;
    background-color: #1e1e20;
    margin: 0;
    padding: 10px;
    color: #ffffff;
  }
  @media only screen and (min-width: 769px) {
    background-color: #1e1e20;
    height: 40%;
    margin-top: -1%;
    padding: 8px;
    color: #ffffff;
  }
`;

export const HeaderSub = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 60%;
    margin-left: 2%;
    height: 100%;
  }
`;

export const LinkButton = styled.button`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    border: none;
    background-color: transparent;
    font-size: 10pt;
    color: #606060;
  }
  @media screen and (min-width: 769px) {
    background-color: transparent;
    border: none;
    font-size: 13px;
    letter-spacing: 0.13px;
    color: ${(props) => props.theme.palette.darkgray};
    text-align: right;
    cursor: pointer;
  }
`;

export const MiddleBar = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    width: 100%;
    height: 2px;
    margin-top: 1%;
    /* margin-left: 5%; */
    background-color: #04083e;
  }
`;

/*=============HomeStartUp============= */

export const HomeStartupWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    margin-left: 30px;
  }
`;
export const StartupWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    margin-right: -50px;
    img {
      width: 70%;
      height: 65%;
    }
  }
`;

/*=============HomeBottom============= */

export const HomeBottomWrapper = styled.div<IStyle>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 55px;
    background-image: url(${(props) => props.url});
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    align-items: center;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.url});
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
`;
export const LargeTiTle = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    color: #ffffff;
    font-size: 12px;
  }
  @media only screen and (min-width: 769px) {
    line-height: 0.19;
    letter-spacing: 0.4px;
    color: #ffffff;
    width: 459px;
    height: 37px;
    cursor: pointer;
  }
`;
export const HomeBottomLeft = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    width: 50%;
    height: 100%;
    padding-top: 4%;
    padding-left: 5%;
    font-size: 30px;
  }
`;

export const HomeBottomRight = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    text-align: center;
    width: 50%;
    height: 55%;
    font-size: 15pt;
    margin-top: 3%;
    div {
      width: 65%;
      height: 60%;
      background-color: black;
      padding: 4% 0 0 3%;
    }
  }
`;

export const MobileBottomRight = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    font-size: 8pt;
    line-height: 14px;
    width: 130px;
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export const StartUpTitle = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    width: 80%;
    font-size: 18px;
    line-height: 1.88;
    letter-spacing: 0.24px;
    text-align: left;
    color: #04083e;
    margin-left: 1%;
    margin-left: 15px;
  }
`;

export const StartUpContainer = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MobileContent = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export const Divider = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 300px;
    margin: 0 auto;
    height: 2px;
    border-bottom: 1px dashed gray;
    background-color: #e5e5e5;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export const MobileKtWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    height: 350px;
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;
