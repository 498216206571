import { gql } from "@apollo/client"



export const LOGIN_CHECK_MUTATION = gql`
	mutation LoginCheck($email: String!, $password: String!) {
		LoginCheck(email: $email, password: $password) {
			ok
			err
			verified
			token
		}
	}
`
