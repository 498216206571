import { createContext, useEffect } from "react"
import { useReactiveVar } from "@apollo/client"
import { GlobalStyle, theme } from "../Styles"
// import { HashRouter as Router } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import jwt_decode from "jwt-decode"


import AppRouter from "./Routes"
import { isLoggedInVar } from "../Apollo/apollo"
import { userLogOut } from "../Apollo/loginResolvers"

const AppContext = createContext({
	isLoggedIn: false,
})

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #04083e;
`

interface tokenProps {
	exp: number
	iat: number
	id: number
}

const App = (): any => {
	const isLoggedIn = useReactiveVar(isLoggedInVar)

	useEffect(() => {
		const token = localStorage.getItem("Bearer")
		if (token) {
			const { exp } = jwt_decode<tokenProps>(token)
			if (exp < Date.now() / 1000) {
				userLogOut()
			}
		}
	}, [])

	useEffect(() => {
		const handleBrowser = () => {
			let browser = navigator.userAgent.toLowerCase();
			if ((navigator.appName === 'Netscape' && browser.indexOf('trident') !== -1) || (browser.indexOf("msie") !== -1)) {
				window.alert("인터넷 익스플로러는 지원 하지 않습니다. 본 사이트는 크롬, 엣지, 파이어폭스에 최적화 되어 있습니다. 해당 브라우저를 이용해 주세요");
			}
		}
		handleBrowser()
		return () => handleBrowser()
	}, [])

	return (
		<Wrapper>
			<AppContext.Provider
				value={{
					isLoggedIn,
				}}
			>
				<ThemeProvider theme={theme}>
					<Helmet>
						<link rel="preconnect" href="https://fonts.gstatic.com" />
						<link
							href={`https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap`}
							rel="stylesheet"
						/>
					</Helmet>
					<GlobalStyle />
					{/* <Router> */}
					<AppRouter />
					{/* </Router> */}
				</ThemeProvider>
				<ToastContainer draggable={true} position={"bottom-right"} />
			</AppContext.Provider>
		</Wrapper>
	)
}

export default App
