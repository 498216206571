import { useQuery } from "@apollo/client"
import { useState } from "react"
import styled from "styled-components"
import { GetBoardByCategory } from "../../../types/api"
import { GET_BOARD_BY_CATEGORY } from "../../SharedQueries"
import {
	NewsWrapper,
	NewsConWrapper,
	NewsDescWrapper,
	Title,
	NewsTitle,
	NewsImg,
	NewsDesc,
	NoticeMoreInfo,
	NewsRow,
	Divider,
} from "../HomeStyles"
import { forceHistory } from "../../../Hooks/forceHistory"

const Wrapper = styled.div`
	@media only screen and (min-width: 320px) and (max-width: 768px) {
		width: 100%;
		height: 320px;
		background-color: #e5e5e5;
		padding: 45px;
		padding-top: 0;
	}
	@media only screen and (min-width: 769px) {
		width: 50%;
		min-width: 420px;
		margin-top: 3%;
		margin-left: 2%;
	}
`
const Container = styled.div`
	@media only screen and (min-width: 320px) and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		width: 375px;
		margin: 0 auto;
	}
	@media only screen and (min-width: 769px) {
		width: 100%;
	}
`

const HomeEvent = () => {
	const [newsData, setNewsData] = useState<any[]>([])
	const { loading } = useQuery<GetBoardByCategory>(GET_BOARD_BY_CATEGORY, {
		variables: {
			category: "news",
		},
		onCompleted: ({ GetBoardByCategory }) => {
			const { ok, err, board } = GetBoardByCategory
			if (ok && board) {
				setNewsData(board && board.slice(0, 2))
			} else {
				console.log(err)
			}
		},
	})
	if (loading) {
		return <>Loading</>
	}
	return (
		<Wrapper>
			<Divider />
			<Container>
				<Title>NEWS</Title>
				{newsData && newsData.length !== 0 ? (
					<NewsWrapper>
						{newsData.map((data: any, index: number) => {
							return (
								<NewsRow
									key={index}
									onClick={() => forceHistory.push(`/NewsDetail/${data.id}`)}
								>
									<NewsConWrapper>
										<NewsImg
											src={
												data.imageUrl
													? data.imageUrl
													: `/images/defaultimg.png`
											}
											alt={"thumbnail"}
										/>
										<NewsDescWrapper>
											<NewsTitle>{data.title}</NewsTitle>
											<NewsDesc
												style={{ maxHeight: 80 }}
												dangerouslySetInnerHTML={{ __html: data.content }}
											></NewsDesc>
										</NewsDescWrapper>
									</NewsConWrapper>
								</NewsRow>
							)
						})}
						<NoticeMoreInfo onClick={() => forceHistory.push("/news/news")}>
							더보기 +
						</NoticeMoreInfo>
					</NewsWrapper>
				) : (
					<NoticeMoreInfo onClick={() => forceHistory.push("/news/news")}>
						더보기 +
					</NoticeMoreInfo>
				)}
			</Container>
		</Wrapper>
	)
}

export default HomeEvent
