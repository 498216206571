import React from 'react'
import { userLogOut } from "../../../Apollo/loginResolvers"
import { forceHistory } from "../../../Hooks/forceHistory"
import { SignUpStyle } from "../SignUp/SignUpStyles"

interface IProps {
	from: string
}

const AuthHeader: React.FC<IProps> = ({ from }) => {

	return (
		<SignUpStyle.HeaderWrapper>
			<SignUpStyle.PCHeader>
				<SignUpStyle.HeaderCol>SUN MOON SW-ORIENTED UNIVERSITY</SignUpStyle.HeaderCol>
				<SignUpStyle.HeaderCol>
					<SignUpStyle.HeaderButton onClick={() => forceHistory.push(`/`)}>
						메인
				</SignUpStyle.HeaderButton>
				|
				{from === 'signup' ?
						<SignUpStyle.HeaderButton onClick={() => forceHistory.push('/login')}>
							로그인
						</SignUpStyle.HeaderButton>
						:
						<SignUpStyle.HeaderButton onClick={() => userLogOut()}>
							로그아웃
				</SignUpStyle.HeaderButton>
					}
				</SignUpStyle.HeaderCol>
			</SignUpStyle.PCHeader>
			<SignUpStyle.MobileHeader>

			</SignUpStyle.MobileHeader>
		</SignUpStyle.HeaderWrapper>
	)
}

export default AuthHeader
