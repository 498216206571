import { gql } from "@apollo/client"

export const VERIFY_EMAIL_MUTATION = gql`
	mutation VerifyEmail($email: String!, $code: String!) {
		VerifyEmail(email: $email, code: $code) {
			ok
			err
			token
		}
	}
`

export const REQUEST_CODE_MUTATION = gql`
	mutation RequestCode($email: String!) {
		RequestCode(email: $email) {
			ok
			err
			code
		}
	}
`
