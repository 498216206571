import { FooterWrapper, FooterLogo, FooterContainer, FooterMobileLogo, Span } from "./FooterStyles"

const MainFooter = ({ hasImage }: any) => {
	return (
		<FooterWrapper hasImage={hasImage}>
			<FooterContainer hasImage={hasImage}>
				<FooterMobileLogo src={`/images/logo_grey.png`} alt="logo" /><br />
				<Span>TEL.&nbsp;041-530-8542~8545&nbsp;&nbsp;&nbsp;FAX.&nbsp;041-530-8549</Span>
				<br />
				<Span>우) 31460 충남 아산시 탕정면 선문로 221번길 70 선문대학교 SW융합관(원화관) 433호</Span>
				<br />
				<Span>70, Sunmoon-ro 221 beon-gil, Tangjeong-myeon, Asan-si, Chungcheongnam-do, Korea ,
				31460</Span>
			</FooterContainer>
			<FooterLogo hasImage={hasImage}>
				<img src={`/images/logo_grey.png`} alt="sunmon-logo" />
			</FooterLogo>
		</FooterWrapper>
	)
}

export default MainFooter
