import styled from "styled-components"

export const AuthStyle = {
	Wrapper: styled.div`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
			width: 100vw;
			background-color: white;
		}
	`,
	ButtonWrapper: styled.div`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
			margin: 15px 0 10px 0;
		}
	`,
	AuthWrapper: styled.div`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
		}
	`,

	Form: styled.form`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
			width: 100%;
			height: 100%;
			margin: 10% 35% 20% 35%;
			display: flex;
			/* align-items: center; */
			/* justify-content: center; */
			flex-direction: column;
			input {
				width: 350px;
				height: 30px;
				padding-left: 10px;
			}
		}
	`,

	LogoWrapper: styled.div`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
		}
	`,

	Input: styled.input`
		@media only screen and (min-width: 320px) and(max-width:767px) {
			width: 300px;
			height: 100px;
			color: #7c7c7c;
		}
		@media screen and (min-width: 769px) {
		}
	`,

	Nbr: styled.div`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
			font-size: 22px;
			line-height: 2;
			letter-spacing: 0.26px;
			text-align: left;
			color: #04083e;
			margin-top: 10%;
		}
	`,

	Desc: styled.div`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
			font-size: 13px;
			letter-spacing: 0.13px;
			text-align: left;
			color: #444444;
			margin-bottom: 10px;
		}
	`,

	CancelBtn: styled.button`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
			border: none;
			width: 80px;
			height: 30px;
			background-color: #878787;
			color: #ffffff;
			font-size: 13px;
			letter-spacing: 0.16px;
			margin-right: 20px;
			cursor: pointer;
			&:hover {
				background-color: ${(props) => props.theme.palette.gray};
				transition: 0.2s linear;
			}
		}
	`,

	ResendBtn: styled.button`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
			border: none;
			width: 115px;
			height: 30px;
			background-color: #ec008c;
			color: #ffffff;
			font-size: 13px;
			letter-spacing: 0.16px;
			cursor: pointer;
			&:hover {
				opacity: 0.5;
				transition: 0.2s linear;
			}
		}
	`,

	ConfirmBtn: styled.button`
		@media only screen and (min-width: 320px) and(max-width:767px) {
		}
		@media screen and (min-width: 769px) {
			border: none;
			width: 115px;
			height: 30px;
			background-color: #04083e;
			color: #ffffff;
			font-size: 13px;
			letter-spacing: 0.16px;
			margin-left: 20px;
			cursor: pointer;
			&:hover {
				opacity: 0.5;
				transition: 0.2s linear;
			}
		}
	`,
}
