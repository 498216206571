import styled from "styled-components";
import { Link } from "react-router-dom";

interface StyleProps {
  theme?: string;
  isOpen?: boolean;
  stylehome?: boolean;
}
interface ShowProps {
  show: boolean;
  home?: boolean;
}

export const Wrapper = styled.div<ShowProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    width: 100%;
  }
  @media screen and (min-width: 769px) {
    position: sticky;
    top: 0;
    z-index: 1000;
    min-height: ${(props) => (props.show ? "30px" : "80px")};
    width: 100%;
    margin: 0 auto;
    background-color: white;
    transition: all 0.4s ease-in-out;
  }
`;

export const TopHeader = styled.div<StyleProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media screen and (min-width: 769px) {
    padding: ${(props) => (props.stylehome ? "0 7.2%" : "0 17.4%")};
    font-size: 12px;
    width: 100%;
    height: 30px;
    display: flex;
    background-color: #04083e;
    transition: all 0.2s ease-in-out;
    color: white;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TopHeaderCol = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    &:first-child {
      width: 60%;
    }
    &:last-child {
      width: 30%;
      display: flex;
      align-items: center;
    }
  }
  @media screen and (min-width: 769px) {
    &:first-child {
      width: 50%;
    }
    &:last-child {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
`;

export const ExtendedLink = styled(Link)`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    font-weight: 600;
    /* &:hover {
      transition: 0.2s linear;
      color: #ec008c;
    } */
  }
`;

export const LogOutButton = styled.button`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    color: white;
    background-color: #04083e;
    border: none;
    font-weight: 800;
    font-size: 13px;
    cursor: pointer;
    line-height: 20px;
    padding-top: 3px;

    &:hover {
      transition: 0.2s linear;
      color: #ec008c;
    }
  }
`;

export const MainHeader = styled.div<ShowProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    display: ${(props) =>
      props.show ? (props.home ? "none" : "flex") : "flex"};
    height: 70px;
    width: ${(props) => (props.home ? "100%" : "68%")};
    /* max-width: 1285px; */
    max-width: 1650px;
    margin: 0 auto;
  }
`;

export const Container = styled.header<StyleProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    justify-content: space-between;
  }
  @media only screen and (min-width: 769px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-right: 1rem;
    justify-content: space-between;
    color: black;
  }
`;

export const HeaderCol = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    &:first-child {
      width: 20%;
    }
    &:last-child {
      /* width: 1281px; */
      justify-content: center;
      padding-left: 10px;
    }
  }
  @media only screen and (min-width: 769px) {
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      width: 70%;
    }
  }
`;

export const HeaderImage = styled.img`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    width: 120%;
    height: auto;
  }
`;

export const PCMenu = styled.div`
  width: 100%;
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const CategoryDivide = styled.div`
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    width: 100%;
    opacity: 0;
  }
`;

export const CategorySecondRow = styled.div`
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    padding: 0 10px;
    visibility: hidden;
    transform: scaleY(0);
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 71px;
    left: -45px;
    width: 107%;
    height: 0px;
    font-size: 14px;
    padding-left: 44px;
    padding-top: 20px;
    background-color: #1e1158;
    /* visibility: visible;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 71px;
    left: -45px;
    width: 107%;
    font-size: 14px;
    padding-left: 44px;
    padding-top: 20px;
    height: 200px; */
  }
`;

export const CategoryRow = styled.div`
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-left: 15px;
    &:hover {
      ${CategorySecondRow} {
        visibility: visible;
        transform: scaleY(1);
        height: 200px;
        opacity: 0.9;
        transition: height 0.1s ease-in;
      }
    }
  }
`;

export const MenuSubCategory = styled.div`
  @media only screen and (min-width: 320px) and(max-width: 768px) {
  }
  @media screen and (min-width: 769px) {
    font-size: 14px;
    &:first-child {
      color: #a39fb4;
      line-height: 15px;
      cursor: unset;
      font-family: AppleMedium;
      font-size: 12px;
    }
    &:not(:first-child) {
      &:hover {
        color: ${(props) => props.theme.palette.secondary};
        transition: 0.2s linear;
      }
    }
    margin: 6px 0;
  }
`;

export const MenuCategoryCol = styled.div<StyleProps>`
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    width: 20%;
    display: flex;
    flex-direction: row;
    padding: 30px 0;
    overflow: visible;
    white-space: nowrap;
    &:nth-child(2) {
      padding-left: 16px;
    }
    &:nth-child(3) {
      padding-left: 10px;
    }
    &:nth-child(4) {
      padding-left: 23px;
    }
    &:nth-child(5) {
      padding-left: 16px;
    }
    &:nth-child(6) {
      padding-left: 30px;
    }
    &:nth-child(7) {
      padding-left: 35px;
    }
    &:nth-child(8) {
      padding-left: 20px;
    }
    &:hover {
      color: ${(props) => props.theme.palette.secondary};
      transition: 0.2s linear;
    }
  }
`;

export const MenuCategory = styled.div`
  font-size: 13px;
  color: "#0b1431";
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    width: 100%;
    cursor: pointer;
  }
`;

export const MenuSubCategoryCol = styled.div`
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: column;
    width: 140px;
    opacity: 0.94;
    /* background-color: #ffffff; */
    color: white;
    background-color: #1e1158;
    &:nth-child(2) {
      padding-left: 15px;
    }
    &:nth-child(3) {
      padding-left: 5px;
    }
    &:nth-child(4) {
      padding-left: 15px;
    }
    &:nth-child(5) {
      padding-left: 6px;
    }
    &:nth-child(6) {
      padding-left: 18px;
    }
    &:nth-child(7) {
      padding-left: 10px;
    }
    &:nth-child(8) {
      padding-left: 15px;
    }
  }
`;

export const MenuContent = styled.div`
  @media only screen and (min-width: 320px) and(max-width:767px) {
  }
  @media screen and (min-width: 769px) {
    margin: 15px;
  }
`;

export const MobileMenu = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

export const Burger = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
  }
  @media screen and (min-width: 769px) {
    cursor: pointer;
    transform: rotate(90deg);
    margin-right: 1rem;
    font-weight: 900;
    font-size: 22px;
  }
`;

export const MobileHeader = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const MobileHeaderRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  &:last-child {
    background-color: #010419;
  }
`;

export const MobileHeaderCol = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    height: 100%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      width: 10%;
    }
    &:last-child {
      width: 90%;
    }
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const MobileHeaderLogo = styled.img`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 200px;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const MobileBurger = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    color: white;
    transform: rotate(90deg);
    font-size: 20px;
    font-family: AppleMedium;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const MobileDrawer = styled.div`
  width: 200px;
  height: 100%;
  background-color: ${(props) => props.theme.palette.primary};
  color: white;
`;

export const DrawerLogoItem = styled.div`
  margin: 10px;
`;

export const DrawerLogo = styled.img`
  width: 100%;
`;

export const MobileTopHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 5px 0;
`;
