import { useMutation } from "@apollo/client"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { userLogIn } from "../../Apollo/loginResolvers"
import MainFooter from "../../Components/Footer/Footer"
import { Wrapper } from "../SharedStyle/Style"
import { REQUEST_CODE_MUTATION, VERIFY_EMAIL_MUTATION } from "./AuthQueries"
import { AuthStyle } from "./AuthStyles"
import { forceHistory } from "../../Hooks/forceHistory"
import useInput from "../../Hooks/useInput"

interface ParamProps {
	email: string
}

const Auth = () => {
	const { email } = useParams<ParamProps>()
	const [code, onChangeCode] = useInput('')

	console.log(email)

	const onRequestClick = (data: any) => {
		const {
			RequestCode: { ok },
		} = data
		if (!ok) {
			toast.error("전송 실패")
		} else {
			toast.success('코드가 전송 되었습니다. 이메일을 확인 해 주세요')
		}
	}
	const [VerifyEmail] = useMutation(VERIFY_EMAIL_MUTATION, {
		onCompleted: ({ VerifyEmail }) => {
			const { ok, err, token } = VerifyEmail
			if (ok && token) {
				toast.success('인증 되었습니다')
				userLogIn(token)
				forceHistory.push("/")
			} else {
				window.alert('인증에 실패 하였습니다.')
				console.log(err)
			}
		}
	})
	const [RequestCode] = useMutation(REQUEST_CODE_MUTATION, {
		variables: {
			email,
		},
		onCompleted: onRequestClick,
	})
	const { register, handleSubmit, getValues } = useForm({
		mode: "onChange",
	})
	const onSubmitValid = (data: any) => {
		const { code } = getValues()
		if (!code) {
			return
		}
		VerifyEmail({
			variables: {
				code,
				email,
			},
		})
	}
	const onCancleClick = async () => {
		const ok = window.confirm("취소하시겠습니까?")
		if (ok) {
			forceHistory.push("/", {
				message: "로그인해주세요.",
			})
		}
	}

	return (
		<>
			<Wrapper>
				<AuthStyle.AuthWrapper>
					<AuthStyle.Form onSubmit={handleSubmit(onSubmitValid)}>
						<AuthStyle.LogoWrapper>
							<img src={`/images/logo.png`} alt="sunmon-logo" style={{ width: '60%' }} />
						</AuthStyle.LogoWrapper>
						<AuthStyle.Nbr>인증번호</AuthStyle.Nbr>
						<AuthStyle.Desc>메일로 받으신 인증번호를 기입해주세요.</AuthStyle.Desc>
						<AuthStyle.Input
							{...register("code", {
								pattern: {
									value: /^[0-9]+$/,
									message: "숫자만 입력하세요.",
								},
								required: "secret code is required",
							})}
							type="text"
							placeholder="인증번호"
							onChange={onChangeCode}
						/>
						<AuthStyle.ButtonWrapper>
							<AuthStyle.CancelBtn onClick={onCancleClick}>취소</AuthStyle.CancelBtn>
							<AuthStyle.ResendBtn onClick={() => RequestCode()}>
								재전송
							</AuthStyle.ResendBtn>
							<AuthStyle.ConfirmBtn type="submit" onClick={() => {
								if (code === '') {
									toast.error('코드를 입력해 주세요')
									return null
								}
								VerifyEmail({
									variables: {
										email,
										code
									}
								})
							}}>인증완료</AuthStyle.ConfirmBtn>
						</AuthStyle.ButtonWrapper>
					</AuthStyle.Form>
				</AuthStyle.AuthWrapper>
			</Wrapper>
			<MainFooter hasImage={true} />
		</>
	)
}

export default Auth
