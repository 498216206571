import { useMutation } from "@apollo/client"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { CREATE_ACCOUNT_MUTATION, EMAIL_DUP_CHECK } from "../../SharedQueries"
import { SignUpStyle } from "./SignUpStyles"
import MainFooter from "../../../Components/Footer/Footer"
import AuthHeader from "../Components/AuthHeader"
import { AuthStyle } from "../AuthStyles"
import useInput from "../../../Hooks/useInput"
import { useEffect, useState } from "react"
import { AiOutlineCheck, AiOutlineLoading3Quarters } from "react-icons/ai"
import { forceHistory } from "../../../Hooks/forceHistory"
import MobileHeader from "../../../Components/Header/MobileHeader"

const SignUp = () => {
	const [email, onChangeEmail] = useInput("")
	const [password, onChangePassword] = useInput("")
	const [pwdCheck, onChangePwdCheck] = useInput("")
	const [firstName, onChangeFirstName] = useInput("")
	const [lastName, onChangeLastName] = useInput("")
	const [phone, onChangePhone] = useInput("")
	const [address, onChangeAddress] = useInput("")
	const [year, onChangeYear] = useInput("")
	const [month, onChangeMonth] = useInput("")
	const [date, onChangeDate] = useInput("")

	const [pwcheck, setPwCheck] = useState<boolean>(true)
	const [dupCheck, setDupCheck] = useState<boolean>(false)

	const regex = {
		email: /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/,
		pwd: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{8,16}$/,
		phone: /^[0-9\b -]{0,13}$/,
		year: /^[0-9]+$/,
		month: /^[0-9]+$/,
		date: /^[0-9]+$/,
	}

	const [createAccount, { loading: cLoading }] = useMutation(CREATE_ACCOUNT_MUTATION, {
		onCompleted: ({ CreateAccount }) => {
			const { ok, err } = CreateAccount
			if (ok) {
				window.alert("회원가입이 완료 되었습니다.\n처음 로그인 시 메일인증이 필요합니다.")
				forceHistory.push(`/login`)
			} else {
				console.log(err)
			}
		},
	})

	const [EmailDupCheck, { loading }] = useMutation(EMAIL_DUP_CHECK, {
		onCompleted: ({ EmailDupCheck }) => {
			const { ok, err } = EmailDupCheck
			if (ok) {
				setDupCheck(true)
			} else {
				toast.error("이미 사용중인 이메일 주소입니다")
				console.log(err)
			}
		},
	})

	const handleDupCheck = async () => {
		if (!regex.email.test(email)) {
			toast.error("올바르지 않은 이메일 형식입니다")
			return
		}
		await EmailDupCheck({
			variables: {
				email,
			},
		})
	}

	const handleSubmit = async () => {
		if (!regex.email.test(email)) {
			toast.error("올바르지 않은 이메일 형식입니다")
			return
		} else if (!regex.pwd.test(password)) {
			toast.error("비밀번호 생성 조건을 확인 해주세요")
			return
		} else if (!regex.phone.test(phone)) {
			toast.error("잘못 된 휴대폰 번호입니다")
			return
		} else if (lastName === '') {
			toast.error("이름을 제대로 입력 해 주세요")
			return
		} else if (firstName === '') {
			toast.error("이름을 제대로 입력 해 주세요")
			return
		}

		await createAccount({
			variables: {
				email,
				password,
				firstName,
				lastName,
				phone,
				address,
				year,
				month,
				date,
			},
		})
	}

	useEffect(() => {
		setDupCheck(false)
	}, [email])

	useEffect(() => {
		if (password !== pwdCheck) {
			setPwCheck(false)
		} else {
			setPwCheck(true)
		}
	}, [password, pwdCheck])

	return (
		<AuthStyle.Wrapper>
			<AuthHeader from={'signup'} />
			<MobileHeader />
			<SignUpStyle.Container>
				<SignUpStyle.SignUpWrapper>
					<SignUpStyle.Form>
						<SignUpStyle.LogoWrapper>
							<img src={`/images/logo.png`} alt="sunmon-logo" />
						</SignUpStyle.LogoWrapper>
						<SignUpStyle.SignUpTitle>회원가입</SignUpStyle.SignUpTitle>
						<SignUpStyle.Desc>
							로그인을 원하시는 경우 회원가입 해주세요.
						</SignUpStyle.Desc>
						<SignUpStyle.InputRow>
							<SignUpStyle.Input
								placeholder="이메일 : example@example.com"
								required={true}
								onChange={onChangeEmail}
								value={email}
								type="email"
							/>
							{!loading ? (
								dupCheck ? (
									<SignUpStyle.ApprovedEmail style={{ fontSize: 12 }}>
										<AiOutlineCheck /> 확인 완료
									</SignUpStyle.ApprovedEmail>
								) : (
									<SignUpStyle.CheckButton onClick={handleDupCheck}>
										중복확인
									</SignUpStyle.CheckButton>
								)
							) : (
								<SignUpStyle.CheckingAni>
									<SignUpStyle.CheckedButton>
										<AiOutlineLoading3Quarters />
									</SignUpStyle.CheckedButton>
								</SignUpStyle.CheckingAni>
							)}
						</SignUpStyle.InputRow>
						<SignUpStyle.InputRow style={{ whiteSpace: 'nowrap' }}>
							<SignUpStyle.Input
								placeholder="8글자 ~ 16글자 사이의 숫자와 영문 조합"
								type="password"
								onChange={onChangePassword}
								value={password}
								required={true}
							/>
							<SignUpStyle.ApprovedEmail style={{ color: "red", fontSize: 12, width: 10 }}>
								8글자 ~ 16글자 사이의 숫자와 영문 조합
								</SignUpStyle.ApprovedEmail>
						</SignUpStyle.InputRow>
						<SignUpStyle.InputRow>
							<SignUpStyle.Input
								placeholder="비밀 번호 체크"
								type="password"
								onChange={onChangePwdCheck}
								value={pwdCheck}
								required={true}
							/>
							{!pwcheck && (
								<SignUpStyle.ApprovedEmail style={{ color: "red", fontSize: 12 }}>
									비밀번호가 맞지 않습니다
								</SignUpStyle.ApprovedEmail>
							)}
						</SignUpStyle.InputRow>
						<SignUpStyle.Input
							placeholder="성"
							type="text"
							onChange={onChangeLastName}
							value={lastName}
						/>
						<SignUpStyle.Input
							placeholder="이름"
							type="text"
							onChange={onChangeFirstName}
							value={firstName}
						/>
						<SignUpStyle.InputMask
							placeholder="휴대폰 번호 : 000-0000-0000"
							mask="999-9999-9999"
							type="text"
							onChange={onChangePhone}
							value={phone}
						/>
						<SignUpStyle.Input
							placeholder="주소"
							type="text"
							onChange={onChangeAddress}
							value={address}
						/>
						<SignUpStyle.BirthWrapper>
							<SignUpStyle.BirthInput
								mask="9999"
								placeholder="생년"
								type="text"
								onChange={onChangeYear}
								value={year}
							/>
							<SignUpStyle.BirthInput
								mask="99"
								placeholder="월"
								type="text"
								onChange={onChangeMonth}
								value={month}
							/>
							<SignUpStyle.BirthInput
								mask="99"
								placeholder="일"
								type="text"
								onChange={onChangeDate}
								value={date}
							/>
						</SignUpStyle.BirthWrapper>

						<SignUpStyle.SignUpBtn
							active={cLoading ? 1 : 0}
							onClick={handleSubmit}
							disabled={cLoading}
						>
							{cLoading ? "Loading..." : "회원가입"}
						</SignUpStyle.SignUpBtn>
						<Link to="/">
							<SignUpStyle.BacktoHome>
								&lt; 메인 홈페이지로 돌아가기
							</SignUpStyle.BacktoHome>
						</Link>
					</SignUpStyle.Form>
				</SignUpStyle.SignUpWrapper>
			</SignUpStyle.Container>
			<MainFooter hasImage={false} />
		</AuthStyle.Wrapper>
	)
}

export default SignUp
