interface IProps {
  palette: {
    primary: string;
    hover: string;
    secondary: string;
    dark: string;
    header: string;
    background: string;
    white: string;
    black: string;
    lightgray: string;
    gray: string;
    darkgray: string;
    orange: string;
    boxBackground: string;
  };
  image: {
    default: string;
  };
}

const theme: IProps = {
  palette: {
    primary: "#0c0e2f",
    hover: "#25499c",
    secondary: "#d82d61",
    dark: "#727171",
    header: "#5d5d5d",
    background: "#151619",
    white: "#ffffff",
    black: "#272727",
    lightgray: "#e2e2e2",
    gray: "#e0e0e0",
    darkgray: "#505050",
    orange: "#f45d48",
    boxBackground: "#f0f0f0",
  },
  image: {
    default:
      "https://firebasestorage.googleapis.com/v0/b/sunmoon-sw.appspot.com/o/defaultImage.png?alt=media&token=f0dc86a3-87f5-4927-87e6-3950e21a6950",
  },
};

export default theme;
