import React from 'react'
import { Page } from '../Style'
import { RouteHandler } from './RouteHandler'

interface IProps {
    param: string
    subParam: string
}

const PageDispatch: React.FC<IProps> = ({ param, subParam }) => {
    return (
        <Page>
            {RouteHandler(param, subParam)}
        </Page>
    )
}

export default PageDispatch