import {
  ApolloClient,
  ApolloLink,
  concat,
  gql,
  HttpLink,
  InMemoryCache,
  makeVar,
  NormalizedCacheObject,
  Operation,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";

const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
  extend type Query {
    adminLogin: Boolean!
  }
`;

export const isLoggedInVar = makeVar<boolean>(!!localStorage.getItem("Bearer"));
export const adminLoginVar = makeVar<boolean>(!!localStorage.getItem("admin"));

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
      },
    },
  },
});

const httpLink = new HttpLink({
  // uri: "http://localhost:4000/graphql",
  uri: "/graphql",
});

const authMiddle = new ApolloLink((operation: Operation, forward: any): any => {
  operation.setContext({
    headers: {
      Authorization: localStorage.getItem("Bearer") || "",
    },
  });
  return forward(operation);
});

const errLink = onError(({ graphQLErrors, networkError }: any) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }: any) =>
      console.log("network graphql error :", message)
    );
  }
});

const linkCombine = split(({ query }) => {
  const { kind }: any = getMainDefinition(query);
  return kind === "OperationDefinition";
}, httpLink);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: ApolloLink.from([errLink, concat(authMiddle, linkCombine)]),
  headers: {
    authorization: localStorage.getItem("Bearer") || "",
  },
  typeDefs,
});

export default client;
