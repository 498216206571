import { useMutation } from "@apollo/client"
import { Link, useLocation } from "react-router-dom"
import { userLogIn } from "../../../Apollo/loginResolvers"
import { Input, Wrapper } from "../../SharedStyle/Style"
import { LOGIN_CHECK_MUTATION } from "./LoginQueries"
import { LoginStyle } from "./LoginStyles"
import MainFooter from "../../../Components/Footer/Footer"
import useInput from "../../../Hooks/useInput"
import { toast } from "react-toastify"
import { forceHistory } from "../../../Hooks/forceHistory"
import MobileHeader from "../../../Components/Header/MobileHeader"
interface RouteProps {
	state: {
		email: string
		password: string
	}
}

const Login = () => {
	const { state }: RouteProps = useLocation()
	const [email, onChangeEmail] = useInput("")
	const [password, onChangePassword] = useInput("")

	const [LoginCheck, { loading }] = useMutation(LOGIN_CHECK_MUTATION, {
		onCompleted: ({ LoginCheck }) => {
			const { ok, err, verified, token } = LoginCheck
			if (!ok) {
				window.alert('가입되지 않은 계정입니다. 확인 해 주세요')
				console.log(err)
			} else {
				if (verified && token) {
					userLogIn(token)
					forceHistory.push("/")
				} else {
					forceHistory.push(`/auth/${email}`, {
						message: "이메일을 인증해주세요.",
					})
				}
			}
		},
	})

	const handleSubmit = () => {
		const emailRegex = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/
		const pwdRegex = /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/

		if (!emailRegex.test(email)) {
			toast.error("올바르지 않은 이메일 형식입니다")
			return
		}
		if (!pwdRegex.test(password)) {
			toast.error("숫자, 영문 조합입니다.")
			return
		}
		LoginCheck({
			variables: {
				email,
				password,
			},
		})
	}

	return (
		<Wrapper>
			<MobileHeader />
			<LoginStyle.Container>
				<LoginStyle.Image url={`/images/loginBg.png`}>
					<LoginStyle.TopWrapper>
						<LoginStyle.TopLogo onClick={() => forceHistory.push("/")}>
							<img src={`/images/logo_white.png`} alt="logo" />
						</LoginStyle.TopLogo>
						<LoginStyle.TopTitle style={{ width: 200 }}></LoginStyle.TopTitle>
					</LoginStyle.TopWrapper>
					<LoginStyle.Box>
						<LoginStyle.Form>
							<LoginStyle.MiddleWrapper>
								<LoginStyle.MiddleTitle>일반 로그인</LoginStyle.MiddleTitle>
								<LoginStyle.MiddleDesc>
									가입하신 이메일로 로그인이 가능합니다.
								</LoginStyle.MiddleDesc>
							</LoginStyle.MiddleWrapper>
							<LoginStyle.FormContainer>
								<LoginStyle.FormLeftContainer>
									<Input
										type="text"
										onChange={onChangeEmail}
										placeholder={"이메일"}
										value={state && state.email ? state.email : email}
										required={true}
									/>
									<Input
										type="password"
										onChange={onChangePassword}
										placeholder={"비밀번호"}
										value={password}
										required={true}
									/>
								</LoginStyle.FormLeftContainer>
								<LoginStyle.FormRightContainer>
									<LoginStyle.LoginButton
										type="submit"
										disabled={loading}
										onClick={handleSubmit}
									>
										{loading ? "Loading..." : "로그인"}
									</LoginStyle.LoginButton>
								</LoginStyle.FormRightContainer>
							</LoginStyle.FormContainer>
							{/* <LoginStyle.SearchWrapper>
								<LoginStyle.PW>비밀번호 찾기</LoginStyle.PW>
							</LoginStyle.SearchWrapper> */}
							<Link to="/" style={{ marginBottom: 10, marginTop: 30, display: 'flex' }}>
								<LoginStyle.BacktoHome>
									&lt; 메인 홈페이지로 돌아가기
								</LoginStyle.BacktoHome>
								<LoginStyle.MobileLink to="/signup">회원가입</LoginStyle.MobileLink>
							</Link>
							<LoginStyle.MiddleBar></LoginStyle.MiddleBar>
							<LoginStyle.Bottom>
								<LoginStyle.MiddleTitle>회원가입</LoginStyle.MiddleTitle>
								<LoginStyle.MiddleDesc>
									로그인을 원하시는 경우 회원가입 해주세요.
								</LoginStyle.MiddleDesc>
							</LoginStyle.Bottom>
							<LoginStyle.PCLink to="/signup">
								<LoginStyle.SignUpButton>회원가입</LoginStyle.SignUpButton>
							</LoginStyle.PCLink>
						</LoginStyle.Form>
					</LoginStyle.Box>
				</LoginStyle.Image>
			</LoginStyle.Container>
			<MainFooter hasImage={false} />
		</Wrapper>
	)
}

export default Login
