import styled from "styled-components";

interface StyleProps {
  hasImage?: boolean;
}

export const FooterMobileLogo = styled.img`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 200px;
    padding-top: 10px;
    margin: 0 auto;
    margin-left: -22px;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const FooterLogo = styled.div<StyleProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
  @media screen and (min-width: 769px) {
    img {
      width: 80%;
      height: 100%;
    }
    margin: 20px 0 0px 0;
  }
`;
export const FooterWrapper = styled.div<StyleProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    color: #707070;
    background-color: #f7f7f7;
    font-size: 9pt;
    height: 180px;
  }
  @media screen and (min-width: 769px) {
    min-height: 180px;
    min-width: 1281px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background-color: ${({ hasImage }) => (hasImage ? "#f7f7f7" : "#04083e")};
  }
`;

export const FooterContainer = styled.div<StyleProps>`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    /* padding: 20px; */
    padding-top: 10px;
    width: 300px;
    margin: 0 auto;
    position: relative;
  }
  @media screen and (min-width: 769px) {
    /* width: 1100px; */
    width: 100%;
    color: ${({ hasImage }) => (hasImage === true ? "#747474" : "#e6e6e6")};
    font-size: 13px;
    line-height: 25px;
  }
`;

export const Span = styled.span`
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    line-height: 1.6;
  }
  @media screen and (min-width: 769px) {
  }
`;
