export const NavigationData = [
  {
    title: "관리",
    param: "manage",
    category: [
      {
        title: "대시보드",
        param: "manage",
        subParam: "dashboard",
      },
      {
        title: "계정관리",
        param: "manage",
        subParam: "account",
      },
      {
        title: "공지사항 관리",
        param: "manage",
        subParam: "notice",
      },
      {
        title: "자료실 관리",
        param: "manage",
        subParam: "storage",
      },
      {
        title: "Q&A 문의관리",
        param: "manage",
        subParam: "question",
      },
      {
        title: "고장신고 관리",
        param: "manage",
        subParam: "report",
      },
    ],
  },
  {
    title: "소개",
    param: "intro",
    category: [
      {
        title: "소개 관리",
        param: "intro",
        subParam: "intro",
      },
      {
        title: "조직도",
        param: "intro",
        subParam: "contact",
      },
      {
        title: "SW중심대학사업",
        param: "intro",
        subParam: "business",
      },
    ],
  },
  {
    title: "NEWS",
    param: "news",
    category: [
      {
        title: "NEWS",
        param: "news",
        subParam: "news",
      },
    ],
  },

  {
    title: "SW비교과 프로그램",
    param: "program",
    category: [
      {
        title: "소개 이미지 관리",
        param: "program",
        subParam: "image",
      },
      {
        title: "프로그램 관리",
        param: "program",
        subParam: "pmanage",
      },
    ],
  },
  {
    title: "국비지원사업",
    param: "aid",
    category: [
      {
        title: "소개 이미지 관리",
        param: "aid",
        subParam: "aimage",
      },
      {
        title: "국비지원사업 관리",
        param: "aid",
        subParam: "kmanage",
      },
    ],
  },
  {
    title: "창업지원프로그램",
    param: "startup",
    category: [
      {
        title: "소개 이미지 관리",
        param: "startup",
        subParam: "simage",
      },
      {
        title: "지원 프로그램 관리",
        param: "startup",
        subParam: "smanage",
      },
    ],
  },

  {
    title: "시설예약",
    param: "reservation",
    category: [
      {
        title: "전산 실습실 관리",
        param: "reservation",
        subParam: "lab",
      },
      {
        title: "시설 예약 관리",
        param: "reservation",
        subParam: "booking",
      },
    ],
  },
];
