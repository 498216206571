import React, { useState } from 'react'
import { Button, Divider, Drawer, List, ListItem, ListItemText, Typography } from "@material-ui/core"
import { forceHistory } from "../../Hooks/forceHistory"
import { DrawerLogo, DrawerLogoItem, MobileBurger, MobileDrawer, MobileHeaderCol, MobileHeaderLogo, MobileHeaderRow, MobileHeader as HeaderWrapper } from "./style"
import { useReactiveVar } from '@apollo/client';
import { userLogOut } from '../../Apollo/loginResolvers';
import { HeaderMenuData } from "./HeaderData"
import { isLoggedInVar } from '../../Apollo/apollo';


type Anchor = 'top' | 'left' | 'bottom' | 'right';

const MobileHeader = () => {
    const isLoggedIn = useReactiveVar(isLoggedInVar)

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const logOutHandler = () => {
        userLogOut()
        forceHistory.push("/")
    }

    const list = (anchor: Anchor) => (
        <MobileDrawer
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <DrawerLogoItem>
                <DrawerLogo src={'/images/logo_white.png'} alt="logo" />
            </DrawerLogoItem>
            <Divider />
            <List>
                {HeaderMenuData.map((item, idx) => {
                    return (
                        <ListItem button key={idx} style={{ marginTop: 7 }} onClick={() => forceHistory.push(`/${item.param}/${item.category[0].subParam}`)}>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    )
                })}
            </List>
            <Divider />
            <List>
                {isLoggedIn ?
                    <ListItem button onClick={() => forceHistory.push('/profile')}>
                        <ListItemText disableTypography primary={<Typography variant='h6' style={{ fontSize: '12px' }}>마이페이지</Typography>} />
                    </ListItem>
                    :
                    <ListItem button onClick={() => forceHistory.push('/signup')}>
                        <ListItemText disableTypography primary={<Typography variant='h6' style={{ fontSize: '12px' }}>회원가입</Typography>} />
                    </ListItem>
                }
                {isLoggedIn ?
                    <ListItem button onClick={() => logOutHandler()}>
                        <ListItemText disableTypography primary={<Typography variant='h6' style={{ fontSize: '12px' }}>로그아웃</Typography>} />
                    </ListItem>
                    :
                    <ListItem button onClick={() => forceHistory.push('/login')}>
                        <ListItemText disableTypography primary={<Typography variant='h6' style={{ fontSize: '12px' }}>로그인</Typography>} />
                    </ListItem>
                }
            </List>
        </MobileDrawer>
    );

    return (
        <HeaderWrapper>
            <MobileHeaderRow>
                <MobileHeaderCol>
                    <div>
                        <Button onClick={toggleDrawer('left', true)}>
                            <MobileBurger>
                                |||
                            </MobileBurger>
                        </Button>
                        <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
                            {list('left')}
                        </Drawer>
                    </div>
                </MobileHeaderCol>
                <MobileHeaderCol onClick={() => forceHistory.push("/")}>
                    <MobileHeaderLogo src={"/images/logo_white.png"} alt="logo" />
                </MobileHeaderCol>
            </MobileHeaderRow>
        </HeaderWrapper>
    )
}

export default MobileHeader