import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IProps {
}

const Wrapper = styled.div`
height: 100%;
width:100%;
font-size:24px;
font-weight:600px;
min-height:30vh;
display:flex;
justify-content:center;
align-items:center;
`


const Loading: React.FC<IProps> = () => {
    const [dots, setDots] = useState(".")
    useEffect(() => {
        let timer: any;
        timer = setTimeout(() => {
            if (dots.length === 3) {
                setDots(".")
            } else {
                setDots(dots + ".")
            }
        }, 500)
        return () => clearTimeout(timer)
    }, [dots])
    return (
        <Wrapper>
            데이터를 가져오는 중 입니다{dots}
        </Wrapper>
    )
}

export default Loading