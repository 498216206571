import styled from "styled-components";
import { Link } from "react-router-dom";

interface StyleProp {
  action: number;
}

export const Wrapper = styled.div`
  min-width: 1080px;
  max-width: 1440px;
  width: 100%;
  min-height: 100vh;
  background-color: #edf0f2;
  margin: 0 auto;
`;

// ==================  Content Style  ==================

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #f6f7f8;
`;

export const Section = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  &:first-child {
    width: 20%;
    background-color: #262e38;
  }
  &:last-child {
    background-color: #f6f7f8;
    width: 80%;
  }
`;

export const ComponentWrapper = styled.div`
  width: 100%;
`;

export const TitleRow = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f6f7f8;
  padding: 0 70px;
`;

export const Title = styled.h1`
  font-size: 30px;
  padding: 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WelcomeMessage = styled.p`
  font-size: 16px;
  color: #6c757d;
  width: 1100px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  box-shadow: 0 1px 2px rgb(216 223 227 / 50%), 0 1px 2px rgb(216 223 227 / 50%);
  & .dashboard-board{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 30px;
`;

export const Page = styled.div`
  width: 100%;
  height: 100%;
`;

// ==================  Navigation Style  ==================

export const Navigation = styled.div`
  height: 100%;
  background-color: #262e38;
  padding: 20px;
`;

export const NavRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.5);
`;

export const NavTitle = styled.h2<StyleProp>`
  color: ${(props) => (props.action ? "#70a1ff" : "rgba(255,255,255,.4);")};
  padding: 8px 0;
`;

export const NavContent = styled.div`
  /* width: 90%; */
  padding: 10px 0;
`;

export const NavSub = styled.div`
  margin: 6px 0px;
  padding: 8px 0;
  width: 100%;
  cursor: pointer;
  &:hover {
    color: white;
    transition: 0.2s linear;
  }
`;

export const NavRoute = styled(Link)``;

export const NavSubTitle = styled.div<StyleProp>`
  display: flex;
  align-items: center;
  height: 24px;
  line-height: 1;
  font-size: 16px;
  color: ${(props) =>
    props.action === 1 ? "white" : "rgba(255, 255, 255, 0.5)"};
  &:hover {
    color: white;
    transition: 0.2s linear;
  }
`;

// ==================  Header Style  ==================

export const Header = {
  Wrapper: styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
  `,
  HeaderCol: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    &:first-child {
      width: 20%;
      background-color: #313942;
    }
    &:last-child {
      width: 15%;
    }
  `,
  HeaderMenu: styled.div`
    padding: 6px 15px;
    margin: 0px 10px;
    cursor: pointer;
    &:hover {
      color: #ec00aa;
      transition: 0.2s linear;
    }
  `,
  HeaderA: styled(Link)`
    padding: 6px 15px;
    margin: 0px 10px;
    cursor: pointer;
    &:hover {
      color: #ec00aa;
      transition: 0.2s linear;
    }
  `,
};

// ==================  Login Box Style  ==================

export const Login = {
  Container: styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #70a1ff;
  `,
  Title: styled.div`
    margin-bottom: 40px;
    color: white;
    font-size: 36px;
  `,
  Box: styled.form`
    display: flex;
    width: 500px;
    height: 300px;
    background-color: #f1f2f6;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;

    -webkit-box-shadow: 0px 5px 15px -1px #2f3542;
    box-shadow: 0px 5px 15px -1px #2f3542;
  `,
  Input: styled.input`
    width: 250px;
    height: 50px;
    padding: 8px 12px;
    margin: 10px auto;
    border: 2px solid #ced6e0;
    border-radius: 4px;
  `,
  Button: styled.button`
    margin: 15px;
    width: 150px;
    height: 40px;
    background-color: #70a1ff;
    color: white;
    border: none;
    cursor: pointer;
    &:hover {
      transition: 0.2s linear;
      background-color: #ff6348;
    }
  `,
};
