import { createGlobalStyle, css } from "styled-components";
import reset from "styled-reset";

const fontFaces = css`
  @font-face {
    font-family: "AppleRegular";
    src: url("./fonts/AppleSDGothicNeoR.ttf") format("truetype"),
      url("./fonts/AppleSDGothicNeoR.woff") format("woff"),
      url("./fonts/AppleSDGothicNeoR.woff2") format("woff2");
    font-style: normal;
    font-weight: normal;
    font-display: auto;
  }
  @font-face {
    font-family: "AppleMedium";
    src: url("./Styles/fonts/AppleSDGothicNeoM.ttf") format("truetype"),
      url("./Styles/fonts/AppleSDGothicNeoM.woff") format("woff"),
      url("./Styles/fonts/AppleSDGothicNeoM.woff2") format("woff2");
  }

  @font-face {
    font-family: "AppleSemiBold";
    src: url("./Styles/fonts/AppleSDGothicNeoSB.ttf") format("truetype"),
      url("./Styles/fonts/AppleSDGothicNeoSB.woff") format("woff"),
      url("./Styles/fonts/AppleSDGothicNeoSB.woff2") format("woff2");
  }

  @font-face {
    font-family: "AppleBold";
    src: url("./Styles/fonts/AppleSDGothicNeoB.ttf") format("truetype"),
      url("./Styles/fonts/AppleSDGothicNeoB.woff") format("woff"),
      url("./Styles/fonts/AppleSDGothicNeoB.woff2") format("woff2");
  }
`;

export default createGlobalStyle`
${reset}
${fontFaces}

* {
    box-sizing: border-box;
    font-family: 'AppleRegular';
}

html {
    margin:0;
    padding:0;
}

a{
    color:inherit;
    text-decoration:none;
}

input, button {
    &:focus, &:active{outline:none}
}
`;
