import { gql } from "@apollo/client";
import client, { adminLoginVar, isLoggedInVar } from "./apollo";

export const query = gql`
  query isLoggedIn {
    isLoggedIn @client
  }
`;

const adminQuery = gql`
  query adminLogin {
    adminLogin @client
  }
`;

export const userLogIn = (token: string) => {
  client.cache.writeQuery({
    query,
    data: {
      isLoggedIn: true,
    },
  });
  localStorage.setItem("Bearer", token);
  isLoggedInVar(true);
};

export const adminLogin = () => {
  client.cache.writeQuery({
    query: adminQuery,
    data: {
      adminLogin: true,
    },
  });
  localStorage.setItem("admin", `${Date.now() + 7200000}`);

  adminLoginVar(true);
};

export const userLogOut = () => {
  client.cache.writeQuery({
    query,
    data: {
      isLoggedIn: false,
    },
  });
  localStorage.removeItem("Bearer");
  isLoggedInVar(false);
};

export const adminLogOut = () => {
  client.cache.writeQuery({
    query: adminQuery,
    data: {
      adminLogin: false,
    },
  });
  localStorage.removeItem("admin");
  adminLoginVar(false);
};
