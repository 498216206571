import { useMutation, useQuery } from "@apollo/client"
import { useState } from "react"
import { Link } from "react-router-dom"
import Loading from "../../Components/Loading"
import AuthHeader from "../Auth/Components/AuthHeader"
import { AuthStyle } from "../Auth/AuthStyles"
import { SignUpStyle } from "../Auth/SignUp/SignUpStyles"
import { EDIT_USER, ME } from "../SharedQueries"
import MainFooter from "../../Components/Footer/Footer"

import useInput from "../../Hooks/useInput"

import { ProfileStyle } from "./ProfileStyles"
import { forceHistory } from "../../Hooks/forceHistory"
import MobileHeader from "../../Components/Header/MobileHeader"

const Profile = () => {
	const [editing, setEditing] = useState<boolean>(false)
	const [userData, setUserData] = useState<any>()
	const [email, onChangeEmail, setEmail] = useInput("")
	const [phone, onChangePhone, setPhone] = useInput("")
	const [address, onChangeAddress, setAddress] = useInput("")
	const { loading } = useQuery(ME, {
		onCompleted: ({ Me }) => {
			const { ok, user } = Me
			if (ok) {
				setUserData(user)
				setEmail(user.email)
				setPhone(user.phone)
				setAddress(user.address)
			}
		},
	})
	const [EditUser] = useMutation(EDIT_USER, {
		onCompleted: ({ EditUser }) => {
			const { ok, err } = EditUser
			if (ok) {
				window.location.reload()
			} else {
				console.log(err)
			}
		},
	})

	if (loading || !userData) {
		return <Loading />
	}
	return (
		<AuthStyle.Wrapper>
			<AuthHeader from={'profile'} />
			<MobileHeader />
			<SignUpStyle.Container>
				<SignUpStyle.SignUpWrapper>
					<SignUpStyle.Form>
						<SignUpStyle.LogoWrapper>
							<img src={`/images/logo.png`} alt="sunmon-logo" />
						</SignUpStyle.LogoWrapper>
						<SignUpStyle.SignUpTitle>마이페이지</SignUpStyle.SignUpTitle>
						<SignUpStyle.Desc>개인정보 변경</SignUpStyle.Desc>
						<ProfileStyle.InputRow>
							<ProfileStyle.Label>이메일</ProfileStyle.Label>
							<ProfileStyle.Input
								value={email}
								required={true}
								type="email"
								onChange={onChangeEmail}
								disabled={true}
							/>
						</ProfileStyle.InputRow>
						{/* <ProfileStyle.Input
							placeholder="비밀 번호 : 숫자와 영문 조합, 최소 8글자, 최대 16글자"
							type="password"t

							onChange={onChangePassword}
							value={password}
							required={true}
						/>
						<ProfileStyle.Input
							placeholder="비밀 번호 체크"
							type="password"
							onChange={onChangePwdCheck}
							value={pwdCheck}
							required={true}
						/> */}
						<ProfileStyle.InputRow>
							<ProfileStyle.Label>성</ProfileStyle.Label>
							<ProfileStyle.Input
								placeholder={userData.lastName}
								type="text"
								disabled={true}
							/>
						</ProfileStyle.InputRow>
						<ProfileStyle.InputRow>
							<ProfileStyle.Label>이름</ProfileStyle.Label>
							<ProfileStyle.Input
								placeholder={userData.firstName}
								type="text"
								disabled={true}
							/>
						</ProfileStyle.InputRow>
						<ProfileStyle.InputRow>
							<ProfileStyle.Label>핸드폰번호</ProfileStyle.Label>
							<ProfileStyle.InputMask
								value={phone}
								mask="999-9999-9999"
								type="text"
								onChange={onChangePhone}
								disabled={editing ? false : true}
							/>
						</ProfileStyle.InputRow>
						<ProfileStyle.InputRow>
							<ProfileStyle.Label>주소</ProfileStyle.Label>
							<ProfileStyle.Input
								value={address}
								type="text"
								onChange={onChangeAddress}
								disabled={editing ? false : true}
							/>
						</ProfileStyle.InputRow>
						<ProfileStyle.InputRow>
							<ProfileStyle.Label>생년월일</ProfileStyle.Label>
							<SignUpStyle.BirthWrapper>
								<ProfileStyle.BirthInput
									placeholder={userData.year}
									type="text"
									disabled={true}
									mask="9999"
								/>
								<ProfileStyle.BirthInput
									placeholder={userData.month}
									type="text"
									disabled={true}
									mask="9999"
								/>
								<ProfileStyle.BirthInput
									placeholder={userData.date}
									type="text"
									disabled={true}
									mask="9999"
								/>
							</SignUpStyle.BirthWrapper>
						</ProfileStyle.InputRow>
						<ProfileStyle.BtnWrapper>
							{editing ? (
								<ProfileStyle.ModifyButton
									onClick={() => {
										setEditing(!editing)
										EditUser({
											variables: {
												id: userData.id,
												phone,
												address,
											},
										})
									}}
								>
									완료
								</ProfileStyle.ModifyButton>
							) : (
								<>
									<ProfileStyle.ModifyButton
										onClick={() => {
											setEditing(!editing)
										}}
									>
										수정하기
									</ProfileStyle.ModifyButton>
								</>
							)}
							<ProfileStyle.ListBtn onClick={() => forceHistory.push("/profile")}>
								취소
							</ProfileStyle.ListBtn>
						</ProfileStyle.BtnWrapper>
						<Link to="/">
							<SignUpStyle.BacktoHome>
								&lt; 메인 홈페이지로 돌아가기
							</SignUpStyle.BacktoHome>
						</Link>
					</SignUpStyle.Form>
				</SignUpStyle.SignUpWrapper>
			</SignUpStyle.Container>
			<MainFooter hasImage={false} />
		</AuthStyle.Wrapper>
	)
}

export default Profile
