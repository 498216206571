import React from "react"
import { useHistory } from "react-router"
import { getDate } from "../../../Hooks/getDate"
import { NoticeDesc, NoticeRow, NoticeWrapper, SubContent, SubDate } from "../HomeStyles"

interface IProps {
	category: string
	data: any[] | undefined
}

const BoardTable: React.FC<IProps> = ({ category, data }) => {
	const history = useHistory()

	const handleTableRow = () => {
		if (data && data.length >= 5) {
			let sliced: any[] = data.slice(0, 5)
			return (
				<NoticeWrapper>
					{sliced.map((elem: any, index: number) => {
						const date = getDate(elem.createdAt)
						return (
							<NoticeRow key={index} active={1}>
								{/* <NoticeRow key={index} active={index % 2 ? 1 : 0}> */}
								<NoticeDesc
									onClick={() => history.push(`/${category}detail/${elem.id}`)}
								>
									<SubContent>{elem.title}</SubContent>
									<SubDate>{date}</SubDate>
								</NoticeDesc>
							</NoticeRow>
						)
					})}
				</NoticeWrapper>
			)
		} else if (data && data.length < 5) {
			return (
				<NoticeWrapper>
					{data.map((elem: any, index: number) => {
						const date = getDate(elem.createdAt)
						return (
							<NoticeRow key={index} active={1}>
								{/* <NoticeRow key={index} active={index % 2 ? 1 : 0}> */}
								<NoticeDesc
									onClick={() => history.push(`/${category}detail/${elem.id}`)}
								>
									<SubContent>{elem.title}</SubContent>
									<SubDate>{date}</SubDate>
								</NoticeDesc>
							</NoticeRow>
						)
					})
					}
				</NoticeWrapper >
			)
		} else {
			return (<NoticeDesc>조회결과가 없습니다.</NoticeDesc>)
		}
	}

	return <NoticeWrapper>{handleTableRow()}</NoticeWrapper>
}

export default BoardTable
