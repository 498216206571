import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { Auth, Profile, SignUp } from "../Route"
import loadable from '@loadable/component'
// import RoutePage from "./RoutePage"
import styled from 'styled-components'

const Wrapper = styled.div`
	@media only screen and (min-width: 320px) and (max-width: 768px) {
		width:100%;
  }
  @media screen and (min-width: 769px) {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #0c0e2f;
	width: 100%;
	min-width:1200px;
  }
`

const Main = loadable(() => import("../Route/Home/Home"))
const Admin = loadable(() => import('../Route/Admin/Admin'))
const Login = loadable(() => import('../Route/Auth/Login/Login'))
const RoutePage = loadable(() => import('./RoutePage'))


const Routes = () => (
	<Switch>
		<Route exact path="/" component={Main} />

		{/* --------------------------- Auth ---------------------------*/}
		<Route path="/signup" component={SignUp} />
		<Route path="/login" component={Login} />
		<Route path="/auth/:email" component={Auth} />
		<Route path="/profile" component={Profile} />

		{/* --------------------------- Admin ---------------------------*/}

		<Route path="/admin/:param/:subparam" component={Admin} />
		<Redirect from="/admin" to="/admin/manage/dashboard" />

		{/* --------------------------- Route ---------------------------*/}
		<Route path="/:param/:subparam" component={RoutePage} />
	</Switch>
)

const AppRouter = () => {
	return (
		<BrowserRouter basename={`/`}>
			<Wrapper>
				<Routes />
			</Wrapper>
		</BrowserRouter>
	)
}
export default AppRouter
