import React from "react"
import { useQuery } from "@apollo/client"
import { useState } from "react"
import Loading from "../../../Components/Loading"
import { GetLectureByCategory_GetLectureByCategory_lecture } from "../../../types/api"
import { GET_LECTURE_BY_CATEGORY } from "../../SharedQueries"
import {
	HomeKtWrapper,
	Title,
	BKDesc,
	Desc,
	HeaderWrapper,
	KtWrappers,
	HeaderSub,
	MoreInfo,
	LinkButton,
	KtImage,
	KtWrapperWD,
	MobileKtWrapper,
	KtWrapper,
	Divider,
} from "../HomeStyles"
import { forceHistory } from "../../../Hooks/forceHistory"

const HomeStartUp = () => {
	const [data, setData] = useState<GetLectureByCategory_GetLectureByCategory_lecture[]>()

	const { loading } = useQuery(GET_LECTURE_BY_CATEGORY, {
		variables: { category: "startup" },
		onCompleted: ({ GetLectureByCategory }) => {
			const { ok, err, lecture } = GetLectureByCategory
			if (ok && lecture) {
				if (lecture && lecture.length > 3) {
					setData(lecture.slice(0, 3))
				} else {
					setData(lecture)
				}
			} else {
				console.log(err)
			}
		},
	})

	if (loading) {
		return <Loading />
	}

	return (
		<HomeKtWrapper style={{ border: "none" }}>
			<Divider />
			<HeaderWrapper>
				<Title>창업지원 프로그램</Title>
				<HeaderSub onClick={() => forceHistory.push(`/startup/s-apply`)}>
					<LinkButton>수강 신청 및 접수 &gt;</LinkButton>
				</HeaderSub>
			</HeaderWrapper>
			<KtWrappers>
				{data &&
					data.map((item, idx) => {
						return (
							<KtWrapperWD
								onClick={() => forceHistory.push(`/startupDetail/${item.id}`)}
								key={idx}
							>
								<div>
									<KtImage src={item.thumbnail} alt="thumbnail" />
								</div>
								<BKDesc>
									<Desc hasTheme={true}>[과정명] {item.title}</Desc>
									<Desc hasTheme={true}>[과정내용] {item.content}</Desc>
									<MoreInfo>
										[신청기간] {item.periodFrom}~{item.periodTo}
									</MoreInfo>
								</BKDesc>
							</KtWrapperWD>
						)
					})}
			</KtWrappers>
			<MobileKtWrapper>
				{data && data[0] ?
					<KtWrapper onClick={() => forceHistory.push(`/programDetail/${data[0].id}`)}>
						<div style={{ height: '290px' }}>
							<KtImage src={data[0].thumbnail} alt="image" />
						</div>
						<BKDesc style={{ height: "30%" }}>
							<Desc hasTheme={true}>[과정명] {data[0].title}</Desc>
							<Desc hasTheme={true}>[과정내용] {data[0].content}</Desc>
						</BKDesc>
					</KtWrapper>
					:
					<div>
						업로드 된 데이터가 없습니다
					</div>
				}
			</MobileKtWrapper>
		</HomeKtWrapper>
	)
}
export default HomeStartUp
