import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { ApolloProvider } from '@apollo/client'
import ReactDOM from 'react-dom';
import client from './Apollo/apollo';
import App from './Components/App';

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);
