import { useEffect, useState } from "react"
import {
	BannerImage,
	BannerTitle,
	BottomBanner,
	ExtendedCarousel,
	HomeBanner,
	ImageBox,
	ImageText,
	TopBanner,
} from "../HomeStyles"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const bannerAsset = [
	{
		url: `/images/banner1.jpeg`,
	},
	{
		url: `/images/banner2.jpeg`,
	},
	{
		url: `/images/banner3.jpeg`,
	},
]

const Banner = () => {
	const [currentItem, setCurrentItem] = useState<number>(0)
	useEffect(() => {
		let timer: any
		timer = setTimeout(() => {
			if (currentItem === bannerAsset.length - 1) {
				setCurrentItem(0)
			} else {
				setCurrentItem(currentItem + 1)
			}
		}, 4000)
		return () => clearTimeout(timer)
	}, [currentItem])
	return (
		<HomeBanner>
			<ExtendedCarousel
				infiniteLoop={true}
				axis={'vertical'}
				interval={3000}
				transitionTime={1000}
				autoPlay={true}
				showArrows={false}
				showThumbs={false}
				showStatus={false}
			>
				<ImageBox>
					<BannerImage src={bannerAsset[0].url} alt="banner" style={{ height: '100%' }} />
				</ImageBox>
				<ImageBox>
					<BannerImage src={bannerAsset[1].url} alt="banner" style={{ height: '100%' }} />
				</ImageBox>
				<ImageBox>
					<BannerImage src={bannerAsset[2].url} alt="banner" style={{ height: '100%' }} />
				</ImageBox>
			</ExtendedCarousel>
			<ImageText>
				<TopBanner>SW파워 인재육성</TopBanner>
				<BannerTitle>SW POWER </BannerTitle>
				<BannerTitle>Person of Talent</BannerTitle>
				<BottomBanner>
					SW파워 인재 '무한 상상에 도전하며 세상을 혁신하는 핵심인재'
				</BottomBanner>
			</ImageText>
		</HomeBanner>
	)
}

export default Banner
