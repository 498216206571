import { HomeBottomWrapper, LargeTiTle, HomeBottomLeft, HomeBottomRight, MobileBottomRight } from "../HomeStyles"
import { forceHistory } from "../../../Hooks/forceHistory"
import { AiOutlineRight } from 'react-icons/ai'

const HomeBottom = () => {
	return (
		<HomeBottomWrapper
			url={`/images/booking.jpeg`}
			onClick={() => forceHistory.push("/reservation/lab")}
		>
			<HomeBottomLeft>
				<LargeTiTle>강의실 및 컴퓨터실 시설 예약</LargeTiTle>
				<LargeTiTle>Reservation</LargeTiTle>
			</HomeBottomLeft>
			<HomeBottomRight>
				<LargeTiTle>예약시스템 바로가기 ----&gt;</LargeTiTle>
			</HomeBottomRight>
			<MobileBottomRight>
				<LargeTiTle>예약시스템 바로가기&ensp; <AiOutlineRight size={8} /></LargeTiTle>
			</MobileBottomRight>
		</HomeBottomWrapper>
	)
}

export default HomeBottom
